import React from 'react'
import {
	Button,
	Typography,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
} from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

type Props = {
	onGetBillingLink: () => void
}

export const TrialManageAccount: React.FC<Props> = ({ onGetBillingLink }) => {
	return (
		<div style={{ marginTop: '20px' }}>
			<div style={{ textAlign: 'center', marginTop: '10px' }}>
				<Typography fontSize={'1.2rem'} fontWeight={'bold'}>
					Subscribe to Joyner for just $24/year
				</Typography>
				<Typography paddingTop={'0.4rem'}>
					Billed $6 every three months
				</Typography>
			</div>
			<Typography paddingTop={'1.0rem'} fontWeight={'bold'}>
				Your subscription includes:
			</Typography>
			<List style={{ paddingTop: '5px' }}>
				<ListItem disablePadding>
					<ListItemIcon
						style={{
							color: 'green',
							justifyContent: 'center',
						}}
					>
						<CheckCircleIcon />
					</ListItemIcon>
					<ListItemText primary='Unlimited Groups' secondary='' />
				</ListItem>
				<ListItem disablePadding>
					<ListItemIcon
						style={{
							color: 'green',
							justifyContent: 'center',
						}}
					>
						<CheckCircleIcon />
					</ListItemIcon>
					<ListItemText
						primary='Unlimited Group Activities'
						secondary=''
					/>
				</ListItem>
				<ListItem disablePadding>
					<ListItemIcon
						style={{
							color: 'green',
							justifyContent: 'center',
						}}
					>
						<CheckCircleIcon />
					</ListItemIcon>
					<ListItemText
						primary='Unlimited Group Members'
						secondary='Group members use Joyner for FREE'
					/>
				</ListItem>
			</List>

			<div style={{ marginTop: '10px', textAlign: 'center' }}>
				<Button
					fullWidth
					variant='contained'
					onClick={onGetBillingLink}
					color='success'
					style={{ maxWidth: '250px' }}
				>
					Subscribe Now
				</Button>
			</div>
		</div>
	)
}
