import queryClient from 'lib/reactQuery'
import supabase from 'lib/supabase'
import { TABLE_GUESTS } from 'lib/supabase'
import type { Profile } from 'lib/supabase'

import type { QueryConfig } from 'lib/reactQuery'
import { useQuery } from '@tanstack/react-query'

type GetManyByActivityIdArgs = {
	activityId?: string
}
const getManyByActivityIdFunction = async ({
	activityId,
}: GetManyByActivityIdArgs) => {
	if (!activityId) return []

	const { data, error } = await supabase
		.from(TABLE_GUESTS)
		.select('*')
		.eq('activity_id', activityId)

	if (error) throw error

	// convert type GUEST to type PROFILE
	const profiles: Profile[] = data.map((guest) => {
		return {
			id: guest.id,
			full_name: '[GUEST] ' + guest.full_name,
			email: null,
			phone_number: '1234567890',
			active_group: null,
			stripe_customer_id: null,
			subscription_status: 'inactive',
			allow_sms: true,
			allow_email: true,
			activity_range: 'month',
			created_at: guest.created_at,
			subscribed_at: null,
			updated_at: null,
			viewed_welcome: false,
			trialing: false,
			last_access_at: null,
			avatar: null,
		}
	})

	return profiles
}

export const getManyByActivityId = async ({
	activityId,
}: GetManyByActivityIdArgs) =>
	queryClient.fetchQuery({
		queryKey: ['guests', 'byActivityId', activityId],
		queryFn: () => getManyByActivityIdFunction({ activityId }),
	})

type UseGetManyByActivityIdOptions = {
	activityId?: string
	options?: QueryConfig<typeof getManyByActivityIdFunction>
}
export const useGetManyByActivityId = ({
	activityId,
	options,
}: UseGetManyByActivityIdOptions) => {
	return useQuery({
		...options,
		queryKey: ['guests', 'byActivityId', activityId],
		queryFn: () => getManyByActivityIdFunction({ activityId }),
	})
}
