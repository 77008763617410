import React from 'react'
import type { Profile } from '../../lib/supabase'
import { Button, Box, Typography, AppBar } from '@mui/material'
import AppHeader from 'components/AppHeader'
import { FormInputSwitch, FormInputText } from 'components/forms/elements'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { object } from 'yup'
import { Validators } from 'utils/validators'

type FormValues = {
	full_name: string
	email: string
	phone_number: string
	allow_email: boolean
	allow_sms: boolean
}

type Props = {
	readonly profile: Profile
	onSave: (newProfile: Partial<Omit<Profile, 'id'>>) => void
	onCancel: () => void
}

const EditProfile: React.FC<Props> = ({ profile, onSave, onCancel }) => {
	const { handleSubmit, control } = useForm<FormValues>({
		resolver: yupResolver(
			object().shape({
				full_name: Validators.NAME.required(),
				email: Validators.EMAIL.required(),
				phone_number: Validators.PHONE.required(),
				allow_email: Validators.BOOLEAN.required(),
				allow_sms: Validators.BOOLEAN.required(),
			})
		),
		defaultValues: {
			full_name: profile.full_name,
			email: profile.email || '',
			phone_number: profile.phone_number || '',
			allow_email: profile.allow_email,
			allow_sms: profile.allow_sms,
		},
	})

	const handleFormSubmit = (data: FormValues) => {
		onSave(data)
	}

	return (
		<Box flexGrow={1}>
			<AppHeader
				leftComponent={
					<Button sx={{ color: '#0195f5' }} onClick={onCancel}>
						Cancel
					</Button>
				}
				title={'Profile'}
			/>
			<form
				id='edit-form'
				onSubmit={handleSubmit(handleFormSubmit)}
				style={{ padding: '2rem' }}
			>
				<FormInputText
					name='full_name'
					variant='outlined'
					label='Name'
					control={control}
				/>

				<FormInputText
					name='email'
					label='Email'
					variant='outlined'
					control={control}
					sx={{ marginTop: '1rem' }}
				/>

				<FormInputText
					name='phone_number'
					label='Mobile Phone Number'
					variant='outlined'
					control={control}
					type='tel'
					disabled
					sx={{ marginTop: '1rem' }}
				/>
				<Typography
					marginTop={'10px'}
					fontSize={'0.8rem'}
					color={'#666666'}
				>
					For security purposes you cannot edit your mobile phone
					number. If your mobile phone number has changed, please
					contact Joyner support.
				</Typography>

				<Typography marginTop={'1rem'}>
					Notification Preferences
				</Typography>

				<FormInputSwitch
					name='allow_email'
					label='Allow Email Notifications'
					control={control}
				/>

				<FormInputSwitch
					name='allow_sms'
					label='Allow SMS Notifications'
					control={control}
				/>

				<Typography
					marginTop={'20px'}
					paddingRight={'10px'}
					fontSize={'0.8rem'}
					color={'#666666'}
				>
					If you turn off SMS and/or Email Notifications, you will
					still receive Joyner notifications via email and/or SMS for
					security and account management purposes, including
					receiving Joyner sign-in codes via SMS.
				</Typography>
			</form>
			<AppBar
				position='fixed'
				sx={{
					top: 'auto',
					bottom: 0,
					background: '#ffffff',
					paddingBottom: '60px',
				}}
			>
				<div
					style={{
						paddingTop: '20px',
						display: 'flex',
						flexDirection: 'column',
						gap: '10px',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<Button
						variant='contained'
						fullWidth
						style={{ maxWidth: '300px' }}
						type='submit'
						form='edit-form'
					>
						Save Changes
					</Button>
				</div>
			</AppBar>
		</Box>
	)
}

export default EditProfile
