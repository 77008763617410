import { Button, Stack, Typography, Paper } from '@mui/material'
import React from 'react'
import SignIn from './SignIn'
import CreateAccount from './CreateAccount'

const AccountInfo: React.FC = () => {
	const [createAccount, setCreateAccount] = React.useState(false)

	const handleChangeToSignIn = () => {
		setCreateAccount(false)
	}

	const handleChangeToCreateAccount = () => {
		setCreateAccount(true)
	}
	const handleHelp = () => {
		const hurl =
			'https://airtable.com/embed/appV1wlnS9BGi44bT/pagwjekNetCHODW0V/form' +
			'?prefill_SenderID=unauthenticated' +
			'&hide_SenderID=true'

		window.open(hurl, '_blank')
	}

	if (createAccount) {
		return (
			<Stack direction='column'>
				<CreateAccount style={{ paddingBottom: '1rem' }} />
				<Button onClick={handleChangeToSignIn}>cancel</Button>
			</Stack>
		)
	} else {
		return (
			<Stack direction='column' marginTop={'10px'}>
				<Paper
					elevation={3}
					sx={{
						padding: '20px 0px',
						// margin: '10px',
						textAlign: 'center',
					}}
				>
					<Typography
						fontWeight={'500'}
						paddingTop={'0.35rem'}
						paddingBottom={'10px'}
					>
						If you are a new user:
					</Typography>
					<Button
						variant='contained'
						onClick={handleChangeToCreateAccount}
					>
						Create an Account
					</Button>
				</Paper>

				<Paper
					elevation={3}
					sx={{ padding: '20px 20px', marginTop: '20px' }}
				>
					<Typography
						fontWeight={'500'}
						paddingTop={'1.0rem'}
						paddingBottom={'0.6rem'}
					>
						Already have a Joyner account?
					</Typography>
					<SignIn style={{ paddingBottom: '1rem' }} />
				</Paper>

				<Stack direction='row' spacing={1} paddingTop={'1.0rem'}>
					<Typography fontWeight={'500'} paddingTop={'0.35rem'}>
						Having trouble?
					</Typography>
					<Button onClick={handleHelp}>Get help signing in</Button>
				</Stack>
			</Stack>
		)
	}
}

export default AccountInfo
