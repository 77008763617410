import React from 'react'
import type { Activity } from '../lib/supabase'

type Props = {
	activity: Activity
}

const Attending: React.FC<Props> = (props) => {
	return (
		<div
			key={Math.random()}
			style={{
				animationName: 'blink',
				animationDuration: '2s',
				animationIterationCount: '1',
				animationTimingFunction: 'ease-in-out',
				borderRadius: '5px',
			}}
		>
			<div
				style={{
					paddingTop: '10px',
					paddingBottom: '10px',
					fontSize: '0.75rem',
					fontStyle: 'italic',
				}}
			>
				<>
					{props.activity.ins.length}{' '}
					{props.activity.max_spots != 0 &&
						`of ${props.activity.max_spots}`}{' '}
					attending
				</>
			</div>
		</div>
	)
}

export default Attending
