import { yupResolver } from '@hookform/resolvers/yup'
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from '@mui/material'
import React from 'react'
import { useForm } from 'react-hook-form'
import { Validators } from 'utils/validators'
import { object } from 'yup'
import { FormInputText } from '../forms/elements'
import type { Venue } from 'lib/supabase'
import { v4 as uuidV4 } from 'uuid'
import { useUserContext } from 'contexts/UserContext'
import { DateTime } from 'luxon'

type FormValues = {
	venueName: string
}

type Props = {
	open: boolean
	setOpen: (open: boolean) => void
	onSubmit: (guest: Venue) => void
}
const QuickAddVenueDialog: React.FC<Props> = ({ open, setOpen, onSubmit }) => {
	const { activeGroup } = useUserContext()
	const { handleSubmit, control, reset } = useForm<FormValues>({
		resolver: yupResolver(
			object().shape({
				venueName: Validators.VENUE_NAME.required(),
			})
		),
		defaultValues: {
			venueName: '',
		},
	})

	const submitHandler = (data: FormValues) => {
		if (!activeGroup) return

		const newVenue: Venue = {
			id: uuidV4(),
			group_id: activeGroup.id,
			name: data.venueName,
			street: null,
			city: null,
			state: null,
			phone: null,
			url: null,
			zip: null,
			created_at: DateTime.now().toISO(),
			updated_at: null,
		}

		onSubmit(newVenue)
		setOpen(false)
		reset()
	}

	return (
		<Dialog
			fullWidth
			open={open}
			onClose={() => setOpen(false)}
			aria-labelledby='alert-dialog-title'
			aria-describedby='alert-dialog-description'
		>
			<DialogTitle id='alert-dialog-title'>
				{'Venue Quick Add'}
			</DialogTitle>
			<DialogContent>
				<form
					id='quick-add-form'
					onSubmit={handleSubmit(submitHandler)}
				>
					<FormInputText
						label='Venue Name'
						name='venueName'
						control={control}
						required
						variant='outlined'
						fullWidth
						style={{ marginTop: '10px' }}
					/>
				</form>
				<DialogContentText
					sx={{
						fontSize: '0.8rem',
						lineHeight: '1.1rem',
						marginTop: '10px',
					}}
				>
					<em>
						Complete the venue&apos;s information by tapping the
						Group Name in the main navigation and then tapping the
						Venues tab.
					</em>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => setOpen(false)}>Cancel</Button>
				<Button
					type='submit'
					form='quick-add-form'
					variant='contained'
					autoFocus
				>
					Add Venue
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default QuickAddVenueDialog
