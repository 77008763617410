import React, { useEffect, useState } from 'react'
import {
	Typography,
	List,
	ListItem,
	ListItemText,
	ListItemButton,
	ListItemIcon,
	Checkbox,
	Radio,
	RadioGroup,
	Button,
	IconButton,
	Divider,
	Box,
	FormControlLabel,
	Paper,
} from '@mui/material'
import AppHeader from 'components/AppHeader'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Poll, PollChoice } from 'lib/supabase'
import PollButtons from './PollButtons'
import { useUserContext } from 'contexts/UserContext'
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog'
import PollResultsDrawer from './PollResultsDrawer'
import { useSnackbarContext } from 'contexts/SnackbarContext'
import { voteController } from 'utils'

type Props = {
	canViewButtons: boolean
	poll: Poll
	choices: PollChoice[]
	onBack: () => void
	onEdit: () => void
	onDelete: () => void
	onSendMessage: () => void
	onVote: (voteChoices: voteController[]) => void
	onReset: () => void
	onClose: () => void
	onSendNotifications: (
		selected: string[],
		message: string,
		type: string
	) => void
}

type PollMeterProps = {
	choices: PollChoice[]
	totalVotes: number
	choiceId: string
}

const PollMeter: React.FC<PollMeterProps> = ({
	choices,
	totalVotes,
	choiceId,
}) => {
	const choice = choices.find((c) => c.id === choiceId)
	const votes = choice ? choice.votes.length : 0
	const percentage = totalVotes === 0 ? 0 : (votes / totalVotes) * 100

	return (
		<div
			style={{
				width: '95%',
				height: '10px',
				borderRadius: '5px',
				background: '#e0e0e0',
				zIndex: '1',
			}}
		>
			<div
				style={{
					width: `${percentage}%`,
					height: '10px',
					background: '#558b2f',
					color: 'green',
					borderRadius: '5px',
					position: 'relative',
					left: '0px',
					top: '0px',
				}}
			></div>
		</div>
	)
}

const ViewPoll: React.FC<Props> = ({
	canViewButtons,
	poll,
	choices,
	onBack,
	onEdit,
	onDelete,
	onSendMessage,
	onVote,
	onReset,
	onClose,
}) => {
	type ConfirmDialogProps = {
		title: string
		content: string
		confirmButtonText: string
		onConfirm: () => void
	}

	const closePollDialogProps: ConfirmDialogProps = {
		title: 'Confirm Close Poll',
		content: 'Are you sure you want to close this poll?',
		confirmButtonText: 'Close Poll',
		onConfirm: () => {
			finishClose()
		},
	}

	const resetPollDialogProps: ConfirmDialogProps = {
		title: 'Confirm Reset Poll',
		content: 'Are you sure you want to reset this poll?',
		confirmButtonText: 'Reset Poll',
		onConfirm: () => {
			finishReset()
		},
	}

	const deletePollDialogProps: ConfirmDialogProps = {
		title: 'Confirm Delete Poll',
		content: 'Do you want to permanently delete this poll?',
		confirmButtonText: 'Delete Poll',
		onConfirm: () => {
			finishDelete()
		},
	}

	const [confirmDialogProps, setConfirmDialogProps] =
		useState<ConfirmDialogProps>({
			title: '',
			content: '',
			confirmButtonText: '',
			onConfirm: () => {},
		})

	const [alreadyVoted, setAlreadyVoted] = useState(false)
	const profile = useUserContext().profile
	const [showPollResults, setShowPollResults] = React.useState(false)
	const { showSnackbar } = useSnackbarContext()
	const [choiceList, setChoiceList] = React.useState([] as voteController[])
	const [radioChoice, setRadioChoice] = React.useState('')
	const [totalVotes, setTotalVotes] = React.useState(0)
	const [showConfirmationDialog, setShowConfirmationDialog] =
		React.useState(false)

	useEffect(() => {
		const voteController: voteController[] = []
		choices.forEach((choice, index) => {
			const checked =
				Array.isArray(choice.votes) && profile?.id
					? choice.votes.indexOf(profile.id) !== -1
					: false
			if (checked) setAlreadyVoted(true)
			voteController.push({
				choice_id: choice.id,
				choice: choice.choice,
				checked: checked,
			})
		})
		setChoiceList(voteController)

		// calculate totals
		calcTotalVotes()
	}, [choices])

	useEffect(() => {
		calcTotalVotes()
	}, [alreadyVoted])

	const calcTotalVotes = () => {
		const total = choices.reduce(
			(sum, choice) => sum + (choice.votes ? choice.votes.length : 0),
			0
		)
		setTotalVotes(total)
	}

	const handleVoteChoice = async (choiceId: string) => {
		if (poll?.choose_multiple) {
			const updatedChoiceList = await choiceList.map((choice) => {
				if (choice.choice_id === choiceId) {
					return {
						...choice,
						checked: !choice.checked,
					}
				}
				return choice
			})
			setChoiceList(updatedChoiceList)
		} else {
			const prevChoice = choiceList.find(
				(c) => c.checked
			) as voteController

			const updatedChoices = choiceList.map((choice) => {
				if (prevChoice && choice.choice_id === prevChoice.choice_id) {
					return {
						...choice,
						checked: false,
					}
				} else if (choice.choice_id === choiceId) {
					return {
						...choice,
						checked: true,
					}
				} else {
					return choice
				}
			})
			if (updatedChoices) {
				setChoiceList(updatedChoices)
				const selectedChoice = updatedChoices.find((c) => c.checked)
				setRadioChoice(selectedChoice ? selectedChoice.choice_id : '')
			}
		}
	}

	const handleVote = (choiceList: voteController[]) => {
		setAlreadyVoted(true)
		onVote(choiceList)
	}

	const handleReset = () => {
		console.log('ViewPoll::handleReset - reset poll')
		setConfirmDialogProps(resetPollDialogProps)
		setShowConfirmationDialog(true)
	}

	const finishReset = () => {
		onReset()
		setChoiceList(
			choiceList.map((choice) => ({ ...choice, checked: false }))
		)
		setAlreadyVoted(false)
		setShowConfirmationDialog(false)
		setShowPollResults(false)
		showSnackbar('Poll has been reset', 'success')
	}

	const handleClose = () => {
		console.log('ViewPoll::handleClose - close poll')
		setConfirmDialogProps(closePollDialogProps)
		setShowConfirmationDialog(true)
	}

	const finishClose = () => {
		onClose()
		setShowConfirmationDialog(false)
		setShowPollResults(false)
		showSnackbar('Poll has been closed', 'success')
	}

	const handleDelete = () => {
		console.log('ViewPoll::handleDelete - delete poll')
		setConfirmDialogProps(deletePollDialogProps)
		setShowConfirmationDialog(true)
	}

	const finishDelete = () => {
		onDelete()
		setShowConfirmationDialog(false)
		showSnackbar('Poll has been deleted', 'success')
	}

	const getVoteCount = (choiceId: string) => {
		if (alreadyVoted || canViewButtons) {
			const choice = choices.find((c) => c.id === choiceId)
			const votes = choice ? choice.votes.length : 0

			const percentage = totalVotes === 0 ? 0 : (votes / totalVotes) * 100
			return percentage.toFixed(0) + '%'
		}
	}

	return (
		<div>
			<AppHeader
				leftComponent={
					<IconButton
						size='large'
						edge='start'
						sx={{ color: 'white' }}
						onClick={onBack}
					>
						<ArrowBackIcon />
					</IconButton>
				}
				title='Poll Details'
				rightComponent={
					<Button
						style={{ display: canViewButtons ? 'block' : 'none' }}
						onClick={() => setShowPollResults(true)}
					>
						Results
					</Button>
				}
			/>

			<div
				style={{
					height: '100vh',
					overflowY: 'scroll',
				}}
			>
				<Box
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						alignContent: 'center',
						margin: '20px 10px 0px 10px',
					}}
				>
					<Typography
						fontSize={'1.2rem'}
						fontWeight={'bold'}
						marginBottom={'10px'}
					>
						{poll?.title}
					</Typography>
					<Typography marginBottom={'20px'}>
						{poll?.message}
					</Typography>
					<Divider style={{ width: '90%' }} />
				</Box>
				<Paper
					elevation={3}
					style={{
						display: poll?.status === 'closed' ? 'block' : 'none',
						margin: '0px 20px',
						padding: '10px 0px',
						textAlign: 'center',
						background: '#ffcdd2',
					}}
				>
					<Typography
						textTransform={'uppercase'}
						fontSize={'1.2rem'}
						fontWeight={'bold'}
						color={'#b71c1c'}
					>
						Poll is closed
					</Typography>
				</Paper>

				<div
					style={{
						marginLeft: '20px',
						marginBottom: '10px',
					}}
				>
					{poll?.choose_multiple ? (
						<List>
							{choices &&
								choiceList.map(
									(choice, index) =>
										choice.choice_id !== '' && (
											<div key={index}>
												<ListItem
													key={choice.choice_id}
													disablePadding
													secondaryAction={getVoteCount(
														choice.choice_id
													)}
												>
													<Divider />
													<ListItemButton
														disabled={
															poll?.status ===
															'closed'
														}
														style={{
															paddingLeft: '0px',
														}}
													>
														<ListItemIcon
															style={{
																minWidth:
																	'30px',
															}}
														>
															<Checkbox
																id={
																	choice.choice_id
																}
																value={
																	choice.choice_id
																}
																edge='start'
																disabled={
																	alreadyVoted ||
																	poll?.status ===
																		'closed'
																}
																checked={
																	choice.checked
																}
																onChange={() =>
																	handleVoteChoice(
																		choice.choice_id
																	)
																}
																tabIndex={-1}
																disableRipple
															/>
														</ListItemIcon>
														<ListItemText
															primary={
																<Typography
																	fontSize={
																		'1.2rem'
																	}
																	fontWeight={
																		'bold'
																	}
																>
																	{
																		choice.choice
																	}
																</Typography>
															}
														/>
													</ListItemButton>
												</ListItem>
												<PollMeter
													choices={choices}
													totalVotes={totalVotes}
													choiceId={choice.choice_id}
												/>
											</div>
										)
								)}
						</List>
					) : (
						<RadioGroup
							name='radio-buttons-group'
							style={{ marginBottom: '40px' }}
							value={radioChoice}
						>
							<List>
								{choices &&
									choiceList.map(
										(choice, index) =>
											choice.choice_id !== '' && (
												<div
													key={choice.choice_id}
													style={{
														paddingLeft: '10px',
														paddingTop: '10px',
													}}
												>
													<ListItem
														key={index}
														disablePadding
														secondaryAction={getVoteCount(
															choice.choice_id
														)}
													>
														<FormControlLabel
															key={
																choice.choice_id
															}
															control={
																<Radio
																	value={
																		choice.choice_id
																	}
																/>
															}
															value={
																choice.choice_id
															}
															checked={
																choice.checked
															}
															disabled={
																alreadyVoted ||
																poll?.status ===
																	'closed'
															}
															label={
																<Typography
																	fontSize='1.2rem'
																	fontWeight={
																		'bold'
																	}
																>
																	{
																		choice.choice
																	}
																</Typography>
															}
															onChange={() =>
																handleVoteChoice(
																	choice.choice_id
																)
															}
														/>
													</ListItem>
													<PollMeter
														choices={choices}
														totalVotes={totalVotes}
														choiceId={
															choice.choice_id
														}
													/>
												</div>
											)
									)}
							</List>
						</RadioGroup>
					)}
				</div>

				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'baseline',
						gap: '10px',
						paddingLeft: '20px',
					}}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							alignItems: 'baseline',
							width: '100%',
						}}
					>
						<div
							style={{
								width: '70%',
								display: 'flex',
								flexDirection: 'row',
								gap: '10px',
								alignItems: 'baseline',
							}}
						>
							<Button
								variant='contained'
								disabled={
									alreadyVoted ||
									choiceList.findIndex((c) => c.checked) ===
										-1
								}
								onClick={() => handleVote(choiceList)}
							>
								Vote
							</Button>

							<Typography
								style={{
									display: alreadyVoted ? 'block' : 'none',
								}}
								fontStyle={'italic'}
								fontSize={'0.8rem'}
							>
								Thank you for voting!
							</Typography>

							<Typography
								style={{
									display: alreadyVoted ? 'none' : 'block',
								}}
								fontStyle={'italic'}
								fontSize={'0.8rem'}
							>
								{poll?.choose_multiple
									? 'Make your choice(s) to vote'
									: 'Make your choice to vote'}
							</Typography>
						</div>
						<Typography
							marginRight={'16px'}
							textAlign={'right'}
							fontSize={'0.8rem'}
						>
							{totalVotes === 1
								? '1 vote cast'
								: totalVotes + ' votes cast'}
						</Typography>
					</div>
				</div>
			</div>

			<PollButtons
				poll={poll}
				visible={canViewButtons}
				onDeleteClick={handleDelete}
				onEditClick={onEdit}
				onSendClick={onSendMessage}
				onViewResultsClick={() => setShowPollResults(true)}
			/>

			<ConfirmationDialog
				{...confirmDialogProps}
				isOpen={showConfirmationDialog}
				onClose={() => setShowConfirmationDialog(false)}
			/>

			<PollResultsDrawer
				isOpen={showPollResults}
				poll={poll}
				choices={choices}
				onResetPoll={handleReset}
				onClosePoll={handleClose}
				onOpen={() => setShowPollResults(true)}
				onClose={() => setShowPollResults(false)}
			/>
		</div>
	)
}

export default ViewPoll
