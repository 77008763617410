import React from 'react'
import {
	ListItem,
	ListItemText,
	ListItemButton,
	ListItemAvatar,
	Avatar,
	Divider,
	Typography,
} from '@mui/material'
import PollOutlinedIcon from '@mui/icons-material/PollOutlined'
import { Poll } from 'lib/supabase'
import { DateTime } from 'luxon'

type Props = {
	poll: Poll
	onClick: (pollId: string) => void
}

const PollsItem: React.FC<Props> = ({ poll, onClick }) => {
	const handleListClick = (pollId: string) => {
		onClick(pollId)
	}

	return (
		<div key={poll.id}>
			<ListItem key={poll.id} disableGutters>
				<ListItemButton onClick={() => handleListClick(poll.id)}>
					<ListItemAvatar>
						<Avatar alt='Joyner'>
							<PollOutlinedIcon />
						</Avatar>
					</ListItemAvatar>
					<ListItemText
						primary={
							<Typography
								fontSize={'1.1rem'}
								fontWeight={'bold'}
								color={'#1565C0'}
							>
								{poll.title}
							</Typography>
						}
						secondary={DateTime.fromISO(
							poll.created_at
						).toLocaleString(DateTime.DATE_HUGE)}
					/>
				</ListItemButton>
			</ListItem>
		</div>
	)
}

export default PollsItem
