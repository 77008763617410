import React from 'react'
import { useForm } from 'react-hook-form'
import { FormInputText, FormInputSwitch } from '../elements'
import { Stack, Typography } from '@mui/material'
import { Validators } from 'utils/validators'
import { object } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

export type PollFormValues = {
	title: string
	message?: string
	choose_multiple?: boolean
	choice_1?: string | null
	choice_2?: string | null
	choice_3?: string | null
	choice_4?: string | null
	choice_5?: string | null
	choice_6?: string | null
}

type Props = {
	id?: string
	defaultValues: PollFormValues
	onSubmit: (data: PollFormValues) => void
}
const PollForm = ({ id, defaultValues, onSubmit }: Props) => {
	const { handleSubmit, control, setValue } = useForm<PollFormValues>({
		resolver: yupResolver(
			object().shape({
				title: Validators.NAME.required(),
				message: Validators.STRING,
				choose_multiple: Validators.BOOLEAN,
			})
		),
		defaultValues: defaultValues,
	})

	return (
		<div>
			<form
				id={id || 'edit-form'}
				onSubmit={handleSubmit(onSubmit)}
				style={{
					padding: '2rem',
					gap: '15px',
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<FormInputText
					name='title'
					label='Poll Title'
					control={control}
					autoComplete='off'
					required
				/>
				<FormInputText
					name='message'
					label='Poll Message'
					multiline
					minRows={3}
					control={control}
					autoComplete='off'
				/>
				<Stack
					direction={'row'}
					gap={'10px'}
					alignItems={'baseline'}
					marginTop={'10px'}
				>
					<Typography fontSize={'1.0rem'} fontWeight={500}>
						Poll Choices
					</Typography>
					<Typography fontSize={'0.6rem'} fontStyle={'italic'}>
						First two choices are required, the rest are optional
					</Typography>
				</Stack>

				<FormInputSwitch
					name='choose_multiple'
					label='Allow multiple choices to be selected'
					control={control}
				/>
				<FormInputText
					name='choice_1'
					label='Choice 1'
					control={control}
					autoComplete='off'
					required
				/>
				<FormInputText
					name='choice_2'
					label='Choice 2'
					control={control}
					autoComplete='off'
					required
				/>
				<FormInputText
					name='choice_3'
					label='Choice 3'
					control={control}
					autoComplete='off'
				/>
				<FormInputText
					name='choice_4'
					label='Choice 4'
					control={control}
					autoComplete='off'
				/>
				<FormInputText
					name='choice_5'
					label='Choice 5'
					control={control}
					autoComplete='off'
				/>
				<FormInputText
					name='choice_6'
					label='Choice 6'
					control={control}
					autoComplete='off'
				/>
			</form>
		</div>
	)
}

export default PollForm
