import React from 'react'
import { Typography, Paper } from '@mui/material'
import CircleIcon from '@mui/icons-material/Circle'
import { DateTime } from 'luxon'
import { SubscriptionInfo } from 'vercel/stripe-utils'
import { Profile } from '../../lib/supabase'
import { getStatusColor } from './util'

type Props = {
	profile: Profile
	subscription: SubscriptionInfo
}

export const TrialSubscriptionDetails: React.FC<Props> = ({
	profile,
	subscription,
}) => {
	return (
		<Paper elevation={3} style={{ padding: '5px 10px' }}>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				<Typography fontSize={'1.2rem'} fontWeight={'bold'}>
					Hope you are enjoying your FREE trial!
				</Typography>
				<Typography paddingTop={'0.4rem'}>
					You can upgrade your subscription at any time.
				</Typography>
			</div>

			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'baseline',
					width: '100%',
					textAlign: 'left',
					margin: '20px 0px 5px 0px',
				}}
			>
				<Typography width={'210px'} fontWeight={'500'}>
					Subscription Status:
				</Typography>
				<Typography textTransform={'capitalize'}>
					<CircleIcon
						style={{
							fontSize: '0.8rem',
							color: getStatusColor(
								profile.subscription_status ?? ''
							),
						}}
					/>
					&nbsp;{profile?.subscription_status}
				</Typography>
			</div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'baseline',
					width: '100%',
					textAlign: 'left',
					margin: '5px 0px 5px 0px',
				}}
			>
				<Typography width={'210px'} fontWeight={'500'}>
					Subscription Type:
				</Typography>
				<Typography>FREE TRIAL</Typography>
			</div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'baseline',
					width: '100%',
					textAlign: 'left',
					margin: '5px 0px 5px 0px',
				}}
			>
				<Typography width={'210px'} fontWeight={'500'}>
					Trial Started:
				</Typography>

				<Typography>
					{subscription &&
						DateTime.fromSeconds(subscription.periodstart).toFormat(
							'MMM d, yyyy'
						)}
				</Typography>
			</div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'baseline',
					width: '100%',
					textAlign: 'left',
					margin: '5px 0px 5px 0px',
				}}
			>
				<Typography width={'210px'} fontWeight={'500'}>
					Trial Ends:
				</Typography>

				<Typography>
					{subscription &&
						DateTime.fromSeconds(subscription.trialend).toFormat(
							'MMM d, yyyy'
						)}
				</Typography>
			</div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'baseline',
					width: '100%',
					textAlign: 'left',
					margin: '5px 0px 5px 0px',
				}}
			>
				<Typography width={'210px'} fontWeight={'500'}>
					Auto-Subscribe:
				</Typography>

				<Typography>OFF</Typography>
			</div>
		</Paper>
	)
}
