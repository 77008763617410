import React from 'react'
import { useUserContext } from 'contexts/UserContext'
import { useParams } from 'react-router'
import Loading from 'components/Loading'
import NewActivity from '../Activity/NewActivity'
import NewPoll from '../Polls/NewPoll'
import NewConversation from '../Chat/NewConversation'
import { useGetManyByGroupId } from 'api/venue'

const CreatePage: React.FC = () => {
	const { profile, activeGroup } = useUserContext()

	// check param and set create mode
	const { createType } = useParams()
	const [creatingActivity, setCreatingActivity] =
		createType === 'activity' ? React.useState(true) : React.useState(false)
	const [creatingPoll, setCreatingPoll] =
		createType === 'poll' ? React.useState(true) : React.useState(false)
	const [creatingConversation, setCreatingConversation] =
		createType === 'conversation'
			? React.useState(true)
			: React.useState(false)

	const venues = useGetManyByGroupId({
		groupId: activeGroup?.id,
		options: { enabled: !!activeGroup },
	})

	if (!activeGroup || !profile) return <Loading />
	if (venues.isLoading) return <Loading />

	return (
		<main>
			{creatingActivity && (
				<NewActivity
					profileId={profile.id}
					venues={venues.data || []}
					activeGroup={activeGroup}
				/>
			)}
			{creatingPoll && (
				<NewPoll profileId={profile.id} activeGroup={activeGroup} />
			)}
			{creatingConversation && (
				<NewConversation
					profileId={profile.id}
					activeGroup={activeGroup}
				/>
			)}
		</main>
	)
}

export default CreatePage
