import React, { useState, useEffect } from 'react'
import { Group, Profile, Message, PrivateChat } from 'lib/supabase'
import { useUserContext } from 'contexts/UserContext'
import { useNavigate } from 'react-router'
import { v4 } from 'uuid'
import { DateTime } from 'luxon'
import {
	Button,
	AppBar,
	TextField,
	Stack,
	Typography,
	Avatar,
	AvatarGroup,
	Divider,
	Icon,
} from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import ErrorIcon from '@mui/icons-material/Error'
import GroupMembersDialog from 'components/dialogs/GroupMembersDialog'
import { useCreateMessage } from 'api/messages'
import { useCreateConversation, getChatsByGroupId } from 'api/conversations'
import { getManyByGroupId } from 'api/profile'
import { stringAvatar } from 'utils'
import AppHeader from 'components/AppHeader'

type NewConversationProps = {
	profileId: string
	activeGroup: Group
}

const NewConversation: React.FC<NewConversationProps> = ({
	profileId,
	activeGroup,
}) => {
	const { profile } = useUserContext()
	const [showGroupMembers, setShowGroupMembers] = useState(false)
	const [groupMembers, setGroupMembers] = useState<Profile[]>([])
	const [selectedMembers, setSelectedMembers] = useState<Profile[]>([])
	const [message, setMessage] = useState('')
	const [existingChats, setExistingChats] = useState<PrivateChat[]>([])
	const [conversationExists, setConversationExists] = useState<string>('')
	const createConversation = useCreateConversation()
	const createMessage = useCreateMessage()
	const navigate = useNavigate()

	useEffect(() => {
		if (profile) {
			const temp: Profile[] = []
			temp.push(profile)
			setSelectedMembers(temp)
		}

		// initialize state variables
		setConversationExists('')
	}, [profile])

	// get group members
	getManyByGroupId({ groupId: activeGroup.id }).then((response) => {
		setGroupMembers(response)
	})

	// get existing chats
	getChatsByGroupId({ groupId: activeGroup.id }).then((response) => {
		setExistingChats(response)
	})

	const handleSelectMembers = () => {
		setShowGroupMembers(true)
	}

	const handleCancel = () => {
		navigate(-1)
	}

	const handleAddMember = (selected: string[]) => {
		// get full profiles from groupMembers based on ids in selected
		const selectedProfiles = groupMembers.filter((member) =>
			selected.includes(member.id)
		)

		setSelectedMembers(selectedProfiles)

		// get chat_id of conversation if already exists for selected members
		const chatExists = existingChats.find(
			(chat) =>
				chat.participants.length === selectedProfiles.length &&
				chat.participants.every((participant) =>
					selectedProfiles.some(
						(profile) => profile.id === participant
					)
				)
		)
		setConversationExists(chatExists?.id || '')
		setShowGroupMembers(false)
	}

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setMessage(event.target.value)
	}

	const handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault()
		console.log("form submitted with message: '" + message + "'")

		const newChatId = v4()
		// construct new private chat object
		const newChat: PrivateChat = {
			id: newChatId,
			created_at: DateTime.now().toISO(),
			created_by: profileId,
			group_id: activeGroup.id,
			participants: selectedMembers.map((member) => member.id),
			status: 'active',
			updated_at: null,
		}
		await createConversation.mutateAsync({ conversation: newChat })

		// construct new message object
		const newMessage: Message = {
			id: v4(),
			sender: profileId,
			activity_id: null,
			chat_id: newChatId,
			group_id: activeGroup.id,
			body: message,
			created_at: DateTime.now().toISO(),
		}
		await createMessage.mutateAsync({ message: newMessage })

		// navigate to new conversation
		navigate(`/chat/${activeGroup.id}/${newChatId}`)
	}

	const handleExistingChat = () => {
		if (conversationExists) {
			navigate(`/chat/${activeGroup.id}/${conversationExists}`)
		} else {
			navigate(`/chat/${activeGroup.id}`)
		}
	}

	return (
		<>
			<AppHeader title='Create New Conversation' />

			<Stack direction='column' gap={2} sx={{ padding: '20px' }}>
				<div
					style={{
						display:
							selectedMembers.length === 0 ? 'block' : 'none',
						paddingTop: '10px',
					}}
				>
					<AvatarGroup
						max={10}
						spacing={12}
						style={{
							display: 'flex',
							justifyContent: 'left',
						}}
					>
						<Avatar
							style={{
								width: 36,
								height: 36,
								fontSize: '1.0rem',
								textTransform: 'uppercase',
							}}
						/>
						<Avatar
							style={{
								width: 36,
								height: 36,
								fontSize: '1.0rem',
								textTransform: 'uppercase',
							}}
						/>
						<Avatar
							style={{
								width: 36,
								height: 36,
								fontSize: '1.0rem',
								textTransform: 'uppercase',
							}}
						/>
					</AvatarGroup>
				</div>

				<div
					style={{
						display:
							selectedMembers.length === 0 ? 'none' : 'block',
						paddingTop: '10px',
					}}
				>
					<AvatarGroup
						max={10}
						spacing={12}
						style={{
							display: 'flex',
							justifyContent: 'left',
						}}
					>
						{selectedMembers.map((member) => (
							<Avatar
								key={member.full_name}
								style={{
									width: 36,
									height: 36,
									fontSize: '1.0rem',
									textTransform: 'uppercase',
								}}
								{...stringAvatar(member.full_name)}
							/>
						))}
					</AvatarGroup>
				</div>

				<Typography
					display={
						selectedMembers.length !== groupMembers.length
							? 'block'
							: 'none'
					}
					paddingLeft={'10px'}
				>
					{selectedMembers.length} member(s) seleted
				</Typography>
				<Stack
					direction={'row'}
					justifyContent={'space-between'}
					alignItems={'center'}
					display={
						selectedMembers.length === groupMembers.length
							? 'flex'
							: 'none'
					}
				>
					<Stack
						direction={'row'}
						justifyContent={'start'}
						alignItems={'center'}
					>
						<Icon color={'warning'}>
							<ErrorIcon />
						</Icon>
						<Typography paddingLeft={'10px'}>
							Send group message instead
						</Typography>
					</Stack>
					<Button variant='outlined' onClick={handleExistingChat}>
						Go
					</Button>
				</Stack>
				<Stack
					direction={'row'}
					justifyContent={'space-between'}
					alignItems={'center'}
					display={conversationExists ? 'flex' : 'none'}
				>
					<Stack
						direction={'row'}
						justifyContent={'start'}
						alignItems={'center'}
					>
						<Icon color={'warning'}>
							<ErrorIcon />
						</Icon>

						<Typography paddingLeft={'10px'}>
							Conversation already exists
						</Typography>
					</Stack>
					<Button variant='outlined' onClick={handleExistingChat}>
						Go
					</Button>
				</Stack>
				<Stack
					direction={'row'}
					justifyContent={'center'}
					alignItems={'center'}
				>
					<Button
						fullWidth
						variant='contained'
						style={{ maxWidth: '300px' }}
						onClick={handleSelectMembers}
					>
						Select Conversation Members
					</Button>
				</Stack>
				<Divider />
				<form
					id={'new-conversation-form'}
					style={{ width: '100%' }}
					onSubmit={handleSubmit}
				>
					<TextField
						multiline
						minRows={6}
						required
						label={'Initial Message'}
						sx={{ marginTop: '20px', width: '100%' }}
						onChange={handleInputChange}
					/>
				</form>
			</Stack>
			<AppBar
				position='fixed'
				sx={{
					top: 'auto',
					bottom: 0,
					background: '#ffffff',
					paddingBottom: '60px',
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						gap: '1.0rem',
						justifyContent: 'end',
						margin: '10px 20px',
					}}
				>
					<Button onClick={handleCancel} variant='text'>
						Cancel
					</Button>
					<Button
						type='submit'
						form='new-conversation-form'
						variant='contained'
						disabled={
							selectedMembers.length === 1 ||
							selectedMembers.length === groupMembers.length ||
							conversationExists !== ''
						}
					>
						Create Conversation
					</Button>
				</div>
			</AppBar>

			<GroupMembersDialog
				isOpen={showGroupMembers}
				activeGroup={activeGroup.id}
				onCancel={() => setShowGroupMembers(false)}
				onList={(selected: string[]) => handleAddMember(selected)}
				ins={[`${profile?.id}`]}
				outs={[]}
				title='Add to Conversation'
				buttonText='Add Selected'
				selectAll={false}
				filterList={true}
				forceCheckCurrentUser={true}
				currentUserId={profileId}
				currentList={selectedMembers.map((member) => member.id)}
			/>
		</>
	)
}

export default NewConversation
