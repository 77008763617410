import React, { useEffect, useState } from 'react'
import { Profile } from 'lib/supabase'
import { Avatar } from '@mui/material'
import { useUserContext } from 'contexts/UserContext'

type Props = {
	profile: Profile
	onClick: () => void
	style?: React.CSSProperties
}

const UserAvatar: React.FC<Props> = ({ profile, onClick, style }) => {
	const { getAvatarUrl } = useUserContext()
	const [avatarUrl, setAvatarUrl] = useState<string>('')
	const [initials, setInitials] = useState<string>('')

	const avatarPath = profile?.id + '.jpeg'
	useEffect(() => {
		const fetchAvatarUrl = async () => {
			if (profile) {
				const url = await getAvatarUrl(avatarPath)
				setAvatarUrl(url)
			}
		}

		const fetchUserInitials = () => {
			const names = profile?.full_name.split(' ')
			const firstInitial = names[0].slice(0, 1)
			const lastInitial = names[names.length - 1].slice(0, 1)
			setInitials(firstInitial + lastInitial)
		}

		fetchAvatarUrl()
		fetchUserInitials()
	}, [profile])

	return (
		<Avatar src={avatarUrl} style={style} onClick={onClick}>
			{initials}
		</Avatar>
	)
}

export default UserAvatar
