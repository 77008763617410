import React, { useState, useEffect } from 'react'
import {
	Dialog,
	DialogTitle,
	DialogContent,
	Typography,
	IconButton,
	LinearProgress,
} from '@mui/material'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { getEmbeddedCheckout, EmbeddedCheckoutData } from 'vercel/stripe-utils'
import { loadStripe } from '@stripe/stripe-js'
import {
	EmbeddedCheckoutProvider,
	EmbeddedCheckout,
} from '@stripe/react-stripe-js'

type StripeEmbeddedCheckoutDialogProps = {
	isOpen: boolean
	onClose: () => void
	type: string
	email: string
	customerId: string
	profileId: string
}

export const StripeEmbeddedCheckoutDialog: React.FC<
	StripeEmbeddedCheckoutDialogProps
> = ({ isOpen, onClose, type, email, customerId, profileId }) => {
	const stripePromise = loadStripe(
		import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY
	)
	const [clientSecret, setClientSecret] = useState<string | null>(null)

	useEffect(() => {
		const fetchSecret = async () => {
			const cs = await getEmbeddedCheckout(
				type,
				email,
				customerId,
				profileId
			).then((data: EmbeddedCheckoutData) => {
				return data.clientSecret
			})
			if (cs) {
				setClientSecret(cs)
			}
		}

		if (isOpen && !clientSecret) {
			fetchSecret()
		}
	}, [isOpen, clientSecret])

	return (
		<Dialog
			open={isOpen}
			onClose={onClose}
			style={{ minHeight: '300px', minWidth: '80%' }}
		>
			<DialogTitle style={{ padding: '0px 0px 0px 10px' }}>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					<Typography fontSize={'1.2rem'} fontWeight={'bold'}>
						Checkout w/ Stripe
					</Typography>
					<IconButton size='large' onClick={onClose}>
						<HighlightOffIcon />
					</IconButton>
				</div>
			</DialogTitle>
			<DialogContent style={{ minHeight: '300px', minWidth: '80%' }}>
				{!clientSecret && <LinearProgress />}
				{clientSecret && (
					<EmbeddedCheckoutProvider
						stripe={stripePromise}
						options={{ clientSecret }}
					>
						<EmbeddedCheckout />
					</EmbeddedCheckoutProvider>
				)}
			</DialogContent>
		</Dialog>
	)
}
