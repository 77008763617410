import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Stack, TextField } from '@mui/material'
import React from 'react'
import { useForm } from 'react-hook-form'
import { Validators } from 'utils/validators'
import { object } from 'yup'
import { FormInputText } from '../elements'

export type OTPFormValues = {
	otp: string
}
type Props = {
	error?: boolean
	errorMessage?: string
	phone?: string
	defaultValues?: OTPFormValues
	style?: React.CSSProperties
	onSubmit: (data: OTPFormValues) => void
	onResendCode: () => void
}
const OTPForm: React.FC<Props> = ({
	error,
	errorMessage,
	phone,
	defaultValues,
	style,
	onSubmit,
	onResendCode,
}) => {
	const { handleSubmit, control } = useForm<OTPFormValues>({
		resolver: yupResolver(
			object().shape({
				otp: Validators.OTP.required(),
			})
		),
		defaultValues: defaultValues || {
			otp: '',
		},
	})

	return (
		<form
			style={{ ...style }}
			onSubmit={handleSubmit(onSubmit)}
			autoComplete='off'
		>
			<Stack
				direction='column'
				spacing={2}
				width='100%'
				alignItems='center'
			>
				{phone && (
					<TextField
						label='Mobile Phone Number'
						disabled
						value={phone}
						variant='standard'
						fullWidth
						helperText='Enter numbers only e.g. 2125551212'
					/>
				)}

				<FormInputText
					name='otp'
					label='Enter code'
					control={control}
					required
					autoFocus
					variant='outlined'
					fullWidth
					helperText={
						errorMessage || 'Enter the code sent to your phone'
					}
					error={error}
				/>

				<Button
					variant='contained'
					type='submit'
					fullWidth
					// sx={{ width: '100%' }}
				>
					Sign In
				</Button>
				<Button fullWidth onClick={onResendCode}>
					Resend Code
				</Button>
			</Stack>
		</form>
	)
}

export default OTPForm
