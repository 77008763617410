import React, { useState } from 'react'
import ActivityForm, {
	type ActivityFormValues,
} from 'components/forms/prebuilt/ActivityForm'
import type { Activity, Group, Venue } from 'lib/supabase'
import { DateTime } from 'luxon'
import { DEFAULT_ERROR_MESSAGE, combineDateAndTime } from 'utils'
import { v4 as uuidV4 } from 'uuid'
import { AppBar, Button } from '@mui/material'
import { useSnackbarContext } from 'contexts/SnackbarContext'
import { useNavigate } from 'react-router'
import SendNotificationDialog from 'components/dialogs/SendNotificationDialog'
import { distributeNotifications } from 'utils'
import { useUserContext } from 'contexts/UserContext'
import { useCreateActivity } from 'api/activity'
import { useCreateVenue, useGetManyByGroupId } from 'api/venue'
import AppHeader from 'components/AppHeader'

type Props = {
	profileId: string
	venues: Venue[]
	activeGroup: Group
}
const NewActivity: React.FC<Props> = ({ profileId, venues, activeGroup }) => {
	const { profile } = useUserContext()
	const { showSnackbar } = useSnackbarContext()
	const navigate = useNavigate()
	const createActivity = useCreateActivity()
	const createVenue = useCreateVenue()

	const [showSendNotificationDialog, setShowSendNotificationDialog] =
		useState(false)
	const [newActivity, setNewActivity] = useState<Activity | null>(null)

	const handleQuickAddVenue = async (venue: Venue): Promise<Venue | null> => {
		const error = await onCreateVenue(venue)
		if (error) {
			console.error(error)
			showSnackbar(DEFAULT_ERROR_MESSAGE, 'error')
			return null
		}
		console.log('Venue successfully created in database in NewActivity.tsx')
		return venue
	}

	const onCreateVenue = async (venue: Venue): Promise<Error | void> => {
		try {
			await createVenue.mutateAsync({ venue })
		} catch (err) {
			return err as Error
		}
	}

	const handleSubmit = async (data: ActivityFormValues) => {
		const datetime = combineDateAndTime(data.date, data.time)

		const activityId = uuidV4()
		const newActivity: Activity = {
			id: activityId,
			group_id: activeGroup!.id,
			name: data.name,
			venue_id: data.venue,
			datetime,
			note: data.note || null,
			link: data.url || null,
			max_spots: data.maxSpots,
			published: true,
			status: 'active',
			ins: [],
			outs: [],
			created_at: DateTime.now().toISO(),
			updated_at: null,
			activity_owner_id: profileId,
		}

		setNewActivity(newActivity)
		await handleCreateActivity(newActivity).then(() => {
			console.log(
				'Actions to take once activity successfully addedd to database'
			)
			setShowSendNotificationDialog(true)
		})
	}

	const handleCreateActivity = async (newActivity: Activity) => {
		if (newActivity === null) return
		try {
			await createActivity
				.mutateAsync({ activity: newActivity })
				.then(() => {
					console.log('Activity successfully created in database')
				})
		} catch (error) {
			console.error(error)
			showSnackbar(DEFAULT_ERROR_MESSAGE, 'error')
			return
		}
	}

	const handleSendNotifications = async (
		sendList: string[],
		addlMessage: string
	) => {
		if (newActivity === null || !profile || !activeGroup) return

		// send notifications
		distributeNotifications(
			'activity',
			'create',
			sendList,
			addlMessage,
			profile.full_name,
			activeGroup.id,
			newActivity.id
		)

		// close dialog & navigate to new activity
		setShowSendNotificationDialog(false)
		navigate(`/activity/${newActivity.id}/dashboard`)
	}

	const handleCancel = (actionTaken: boolean) => {
		if (actionTaken) {
			// new activity has already been created, user is bailing on sending notifications
			navigate(`/activity/${newActivity?.id}/dashboard`)
		} else {
			// user is bailing on creating new activity
			navigate('/')
		}
	}

	return (
		<>
			<AppHeader title='Create New Activity' />

			<ActivityForm
				id='new-activity-form'
				venues={venues}
				onSubmit={handleSubmit}
				defaultValues={{
					name: '',
					venue: '',
					date: DateTime.now().startOf('hour').toISO(),
					time: DateTime.now().startOf('hour').toISO(),
					note: '',
					url: '',
					maxSpots: 0,
				}}
				onQuickAddVenue={handleQuickAddVenue}
			/>

			<SendNotificationDialog
				isOpen={showSendNotificationDialog}
				onCancel={(actionTaken: boolean) => handleCancel(actionTaken)}
				activeGroup={activeGroup?.id}
				profileId={profile?.id}
				selectAll={true}
				forceSendAll={false}
				title='New Activity'
				textFieldLabel='Additional Information (optional)'
				showConfirm={false}
				showConfirmText=''
				confirmOnly={false}
				dialogType='notify'
				dialogAction='new'
				objectType='activity'
				onUpdateDeleteAction={() => {}}
				onSendNotifications={(
					selected: string[],
					addlMessage: string
				) => {
					handleSendNotifications(selected, addlMessage)
				}}
			/>

			<AppBar
				position='fixed'
				sx={{
					top: 'auto',
					bottom: 0,
					background: '#ffffff',
					paddingBottom: '40px',
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						gap: '1.0rem',
						justifyContent: 'end',
						margin: '10px 20px',
					}}
				>
					<Button onClick={() => handleCancel(false)}>Cancel</Button>
					<Button
						variant='contained'
						type='submit'
						form='new-activity-form'
					>
						Create Activity
					</Button>
				</div>
			</AppBar>
		</>
	)
}

export default NewActivity
