import React from 'react'
import { Typography, Button } from '@mui/material'

type Props = {
	onGetBillingLink: () => void
}

export const PaidManageAccount: React.FC<Props> = ({ onGetBillingLink }) => {
	return (
		<div>
			<Typography
				paddingTop={'2.4rem'}
				fontWeight={'500'}
				fontSize={'1.0rem'}
			>
				Subscriptions are managed in Stripe to:
			</Typography>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'start',
				}}
			>
				<ul
					style={{
						textAlign: 'left',
						margin: '0px',
						fontSize: '0.9rem',
					}}
				>
					<li style={{ paddingTop: '0.2rem' }}>
						Cancel your subscription
					</li>
					<li style={{ paddingTop: '0.2rem' }}>
						View your payment history
					</li>
					<li style={{ paddingTop: '0.2rem' }}>
						Update your payment method
					</li>
				</ul>
			</div>
			<div style={{ marginTop: '20px', textAlign: 'center' }}>
				<Button
					variant='contained'
					color='primary'
					fullWidth
					style={{ maxWidth: '250px' }}
					onClick={onGetBillingLink}
				>
					Manage My Subscription
				</Button>
			</div>
		</div>
	)
}
