export const sendNotification = async (furl: string) => {
	new Promise((resolve, reject) => {
		fetch(furl, {
			method: 'GET',
			headers: {
				'Content-Type':
					'application/x-www-form-urlencoded; charset=UTF-8',
			},
		})
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => {
				console.error('Error:', error)
				reject(error)
			})
	})
		.then((data) => {
			return data
		})
		.catch((error) => {
			return error
		})
}

type ServerResponse = {
	stripeinfo: object
	subscription: object
}

export const sendServerRequest = async (method: string, furl: string) => {
	return new Promise<ServerResponse>((resolve, reject) => {
		fetch(furl, {
			method: method,
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then((response) => response.json())
			.then((data: ServerResponse) => {
				resolve(data)
			})
			.catch((error) => {
				reject(error)
			})
	})
		.then((data: ServerResponse) => {
			return data
		})
		.catch((error) => {
			return error
		})
}
