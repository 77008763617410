import React from 'react'
import { BrowserRouter, Route } from 'react-router-dom'
import { SentryRoutes } from 'lib/sentry'
import Login from 'pages/Login/Login'
import OTPChallengePage from 'pages/Login/OTPChallengePage'
import CreateAccount from 'pages/Login/CreateAccount'
import ProtectedRoute from 'components/ProtectedRoute'
import MainLayout from 'pages/Main/MainLayout'
import Activities from 'pages/Activity/Activities'
import Account from 'pages/Account/Account'
import Support from 'pages/Support'
import Purchase from 'pages/Account/Purchase'
import ActivityPage from 'pages/Activity/ActivityPage'
import GroupList from 'pages/Group/GroupList'
import GroupPage from 'pages/Group/GroupPage'
import Messages from 'pages/Chat/Messages'
import Polls from 'pages/Polls/PollsList'
import PollPage from 'pages/Polls/PollPage'
import PageNotFound from 'pages/PageNotFound'
import UserProfile from 'pages/Profile/UserProfile'
import VenueList from 'pages/Group/VenuesTab'
import Venue from 'pages/Venue/VenuePage'
import ChatPage from 'pages/Chat/ChatPage'
import Invite from 'pages/Invite/InvitePage'
import CreatePage from 'pages/Main/CreatePage'

const AppRoutes: React.FC = () => (
	<BrowserRouter>
		<SentryRoutes>
			<Route path='/login' element={<Login />} />
			<Route path='/otp/:challengeId' element={<OTPChallengePage />} />
			<Route path='/create' element={<CreateAccount />} />
			<Route path='group/join/:inviteId' element={<Invite />} />
			{/* below are the protected routes that require logging in */}
			<Route path='/' element={<ProtectedRoute />}>
				<Route path='' element={<MainLayout />}>
					<Route path='' element={<Activities />} />
					<Route path='polls' element={<Polls />} />
					<Route path='messages' element={<Messages />} />
				</Route>
			</Route>
			<Route path='create/:createType' element={<CreatePage />} />
			<Route path='poll/:pollId' element={<PollPage />} />
			<Route path='activity/:activityId' element={<ActivityPage />} />
			<Route
				path='activity/:activityId/:referredBy'
				element={<ActivityPage />}
			/>
			<Route path='profile/:userId' element={<UserProfile />} />
			<Route path='chat/:groupId' element={<ChatPage />} />
			<Route path='chat/:groupId/:chatId' element={<ChatPage />} />
			<Route path='venues' element={<VenueList />} />
			<Route path='venue' element={<Venue />} />
			<Route path='venue/:venueId' element={<Venue />} />
			<Route path='groups' element={<GroupList />} />
			<Route path='group/:groupId' element={<GroupPage />} />
			<Route
				path='group/:groupId/:tabToOpen/:referredBy'
				element={<GroupPage />}
			/>
			<Route path='account' element={<Account />} />
			<Route path='support' element={<Support />} />
			<Route path='purchase' element={<Purchase />} />
			<Route path='*' element={<PageNotFound />} />
		</SentryRoutes>
	</BrowserRouter>
)

export default AppRoutes
