import React from 'react'
import { Typography } from '@mui/material'
import pbstripe from '../../images/stripe-black.svg'

export const AboutStripe = () => {
	return (
		<div
			style={{
				margin: '0px 20px',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
			}}
		>
			<div>
				<Typography
					fontSize={'0.7rem'}
					color={'#777777'}
					marginTop={'2.0rem'}
					paddingY={'0.8rem'}
					borderTop={'1px solid #cccccc'}
					textAlign={'center'}
				>
					Stripe is a technology company that builds economic
					infrastructure for the internet. Businesses of every
					size—from new startups to public companies—use our software
					to accept payments and manage their businesses online.
				</Typography>
			</div>
			<div>
				<a
					href='https://www.stripe.com'
					target='_blank'
					rel='noreferrer'
				>
					<img src={pbstripe} style={{ width: '100px' }}></img>
				</a>
			</div>
		</div>
	)
}
