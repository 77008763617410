import React, { useState, useEffect } from 'react'
import { DateTime } from 'luxon'
import PhoneIcon from '@mui/icons-material/Phone'
import CloudQueueIcon from '@mui/icons-material/CloudQueue'
import PlaceIcon from '@mui/icons-material/Place'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { useGetProfile } from '../../api/profile'
import {
	Box,
	Button,
	IconButton,
	Stack,
	Chip,
	Card,
	CardContent,
	CardActions,
	Typography,
} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import AppHeader from 'components/AppHeader'
import type { Activity, Profile, Venue } from 'lib/supabase'
import ActivityButtons from './ActivityButtons'
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog'

const ios = navigator.userAgent.toLowerCase().includes('iphone')
const android = navigator.userAgent.toLowerCase().includes('android')

function convertAddressToMapsUrl(
	street: string,
	city: string,
	state: string,
	zip: string
): string {
	let mapUrl = ''
	if (ios) {
		mapUrl =
			'https://maps.apple.com/?address=' +
			encodeURI(street) +
			',' +
			encodeURI(city) +
			',' +
			encodeURI(state) +
			',' +
			encodeURI(zip)
	}
	if (android) {
		mapUrl =
			'https://www.google.com/maps/search/?api=1&query=' +
			encodeURI(street) +
			',' +
			encodeURI(city) +
			',' +
			encodeURI(state) +
			',' +
			encodeURI(zip)
	}
	return mapUrl
}

function convertAddressToWeatherUrl(city: string, zip: string) {
	let weatherUrl = ''
	weatherUrl =
		'https://www.accuweather.com/en/search-locations?query=' +
		encodeURI(city) +
		',' +
		encodeURI(zip) +
		' US'
	return weatherUrl
}

type Props = {
	canViewButtons: boolean
	ins: Profile[]
	outs: Profile[]
	venue: Venue
	activity: Activity
	onBack: () => void
	onEdit: () => void
	onDelete: () => void
	onRemoveIn: (uid: string) => void
	onShowAddGuest: () => void
	onShowAddMember: () => void
	onShowSendMessage: () => void
}

const ViewActivity: React.FC<Props> = ({
	canViewButtons,
	ins,
	outs,
	venue,
	activity,
	onBack,
	onEdit,
	onDelete,
	onRemoveIn,
	onShowAddGuest,
	onShowAddMember,
	onShowSendMessage,
}) => {
	const [showDeleteDialog, setShowDeleteDialog] = useState(false)
	const { data: activityOwner } = useGetProfile({
		profileId: activity.activity_owner_id || '',
	})

	return (
		<>
			<Box sx={{ flexGrow: 1 }}>
				<AppHeader
					leftComponent={
						<IconButton
							size='large'
							edge='start'
							sx={{ color: 'white' }}
							onClick={onBack}
						>
							<ArrowBackIcon />
						</IconButton>
					}
					title='Activity Details'
				/>
			</Box>

			{/* EXPIRED BANNER */}
			<Box
				style={{
					display: activity.status === 'expired' ? 'block' : 'none',
					background: '#ef5350',
				}}
			>
				<Typography
					textAlign={'center'}
					color={'#ffffff'}
					fontSize={'1.2rem'}
					fontWeight={'bold'}
					textTransform={'uppercase'}
					paddingY={'10px'}
				>
					ACTIVITY IS EXPIRED
				</Typography>
			</Box>

			{/* ACTIVITY NAME */}
			<Box
				color={'#333333'}
				bgcolor={'#eeeeee'}
				borderTop={'4px solid #ffffff'}
				padding={'10px 0px 10px 20px'}
			>
				<Typography
					fontWeight={'bold'}
					fontSize={'1.6rem'}
					textAlign={'left'}
				>
					{activity.name}
				</Typography>
				<Typography fontSize={'0.6rem'} color={'#666666'}>
					Posted by {activityOwner?.full_name}
				</Typography>
			</Box>

			{/* ACTIVITY DATE & VENUE */}
			<Stack
				direction='row'
				spacing={2}
				borderTop={'4px solid #ffffff'}
				sx={{
					background: '#eeeeee',
					marginBottom: '10px',
				}}
			>
				<div
					style={{
						textAlign: 'center',
						width: '140px',
						borderRight: '4px solid #ffffff',
					}}
				>
					<Typography
						fontSize={'1.2rem'}
						fontWeight={'500'}
						color={'#333333'}
						style={{
							paddingTop: '10px',
						}}
					>
						{DateTime.fromISO(activity.datetime).toFormat(
							'ccc LLL d'
						)}
					</Typography>
					<Typography color={'#333333'} style={{ paddingTop: '5px' }}>
						{DateTime.fromISO(activity.datetime)
							.toFormat('h:mm a ZZZZ')
							.toLocaleString()}
					</Typography>
				</div>
				<div style={{}}>
					<Typography
						fontWeight={'bold'}
						fontSize={'1.2rem'}
						style={{
							paddingTop: '10px',
							paddingBottom: '10px',
						}}
					>
						{venue.name}
					</Typography>
					<div style={{ paddingBottom: '5px' }}>
						<Stack spacing={4} direction='row'>
							<IconButton
								size='small'
								color='primary'
								disabled={venue.url == null}
								href={venue.url || ''}
								target='_blank'
								referrerPolicy='no-referrer'
							>
								<OpenInNewIcon />
							</IconButton>
							<IconButton
								size='small'
								color='primary'
								disabled={venue == null || venue.city == null}
								href={convertAddressToMapsUrl(
									venue.street || '',
									venue.city || '',
									venue.state || '',
									venue.zip || ''
								)}
								target='_blank'
								referrerPolicy='no-referrer'
							>
								<PlaceIcon />
							</IconButton>
							<IconButton
								size='small'
								color='primary'
								disabled={venue.phone == null}
								href={`tel:${venue.phone}`}
							>
								<PhoneIcon />
							</IconButton>
							<IconButton
								size='small'
								color='primary'
								disabled={venue == null || venue.city == null}
								href={convertAddressToWeatherUrl(
									venue.city || '',
									venue.zip || ''
								)}
								target='_blank'
								referrerPolicy='no-referrer'
							>
								<CloudQueueIcon />
							</IconButton>
						</Stack>
					</div>
				</div>
			</Stack>

			{/* ACTIVITY ADDITIONAL INFORMATION */}
			<div style={{ textAlign: 'center', marginTop: '20px' }}>
				<div
					style={{
						paddingTop: '10px',
						paddingLeft: '20px',
						paddingRight: '20px',
					}}
				>
					<Card variant='outlined'>
						<CardContent>
							{activity.note || 'No additional information'}
						</CardContent>

						{activity.link && (
							<CardActions>
								<Button
									size='small'
									onClick={() =>
										window.open(activity.link!, '_blank')
									}
								>
									More Info
								</Button>
							</CardActions>
						)}
					</Card>
				</div>
			</div>

			{/* IN LIST */}
			<Typography
				fontSize={'1.2rem'}
				fontWeight={'bold'}
				style={{
					textAlign: 'center',
					marginTop: '30px',
				}}
			>
				Who&apos;s In
			</Typography>
			<div style={{ textAlign: 'center', margin: '5px' }}>
				{ins && ins.length > 0 ? (
					ins.map((user) => {
						if (!user) return null

						return canViewButtons ? (
							<Chip
								key={user.id}
								label={user.full_name}
								style={{ margin: '0px 2px 4px 2px' }}
								onDelete={() => onRemoveIn(user.id)}
							/>
						) : (
							<Chip
								key={user.id}
								label={user.full_name}
								style={{ margin: '0px 2px 4px 2px' }}
							/>
						)
					})
				) : (
					<>
						<Typography style={{ marginTop: '3px' }}>
							No one yet!
						</Typography>
					</>
				)}
			</div>
			{activity.max_spots === 0 ? (
				<Typography
					fontSize={'0.8rem'}
					fontStyle={'italic'}
					style={{
						textAlign: 'center',
						marginTop: '10px',
					}}
				>
					{activity.ins.length} attending
				</Typography>
			) : (
				<Typography
					fontSize={'0.8rem'}
					fontStyle={'italic'}
					style={{
						textAlign: 'center',
						marginTop: '10px',
					}}
				>
					{activity.max_spots - activity.ins.length} of{' '}
					{activity.max_spots} spots available
				</Typography>
			)}
			<div
				style={{
					display: canViewButtons ? 'flex' : 'none',
					flexDirection: 'row',
					justifyContent: 'center',
					gap: '10px',
					paddingTop: '10px',
				}}
			>
				<Button
					variant='contained'
					size='small'
					disabled={activity.status === 'expired'}
					onClick={onShowAddMember}
				>
					Add Member
				</Button>
				<Button
					variant='contained'
					size='small'
					disabled={activity.status === 'expired'}
					onClick={onShowAddGuest}
				>
					Add Guest
				</Button>
			</div>

			{/* OUT LIST */}
			<Typography
				fontSize={'1.2rem'}
				fontWeight={'bold'}
				style={{
					textAlign: 'center',
					marginTop: '20px',
				}}
			>
				Who&apos;s Out
			</Typography>
			<div style={{ textAlign: 'center', marginTop: '5px' }}>
				{outs && outs.length > 0 ? (
					outs.map((user) => {
						if (!user) return null

						return (
							<Chip
								key={user.id}
								label={user.full_name}
								style={{ margin: '0px 2px 4px 2px' }}
							/>
						)
					})
				) : (
					<>
						<Typography style={{ marginTop: '3px' }}>
							No one yet!
						</Typography>
					</>
				)}
			</div>

			<ActivityButtons
				visible={canViewButtons}
				activityExpired={activity.status === 'expired'}
				onEditClick={onEdit}
				onSendNoteClick={onShowSendMessage}
				onDeleteClick={() => setShowDeleteDialog(true)}
			/>

			<ConfirmationDialog
				isOpen={showDeleteDialog}
				title='Delete Activity'
				content='Are you sure you want to delete this activity?'
				confirmButtonText='Delete'
				onConfirm={() => {
					onDelete()
					setShowDeleteDialog(false)
				}}
				onClose={() => setShowDeleteDialog(false)}
			/>
		</>
	)
}

export default ViewActivity
