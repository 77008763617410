import { sendNotification } from '../vercel/apicalls'

export function distributeNotifications(
	notificationTopic: string,
	notificationType: string,
	recipientList: string[],
	addlMessage: string,
	senderFullName: string,
	groupId: string,
	objectId: string
) {
	const host = import.meta.env.VITE_VERCELAPI_HOST
	const efurl = host + 'email/' + notificationTopic
	const sfurl = host + 'twilio/' + notificationTopic

	let parms = ''
	parms += '?cat=' + encodeURIComponent(notificationType || '')
	parms += '&sid=' + encodeURIComponent(senderFullName || '')
	parms += '&gid=' + encodeURIComponent(groupId || '')
	parms +=
		'&oid=' + encodeURIComponent(notificationTopic + '+' + objectId || '')
	parms += '&msg=' + encodeURIComponent(addlMessage)
	parms += '&rec=' + encodeURIComponent(recipientList.join(','))

	sendNotification(efurl + parms)
	// sendNotification(sfurl + parms)
}
