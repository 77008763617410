import React, { useState } from 'react'
import {
	Box,
	Divider,
	List,
	IconButton,
	Stack,
	Typography,
} from '@mui/material'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline'
import GroupMemberItem from './GroupMemberItem'
import { Group, Profile } from '../../lib/supabase'
import InviteLink from 'components/InviteLink'
import { useGetManyByGroupId } from 'api/profile'
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog'
import { useSnackbarContext } from 'contexts/SnackbarContext'

type MembersTabProps = {
	isAdmin: boolean
	group: Group
	currentUser: Profile
	onRemove: (profileId: string) => void
	onLeave: (profileId: string) => void
}

const MembersTab: React.FC<MembersTabProps> = ({
	isAdmin,
	group,
	currentUser,
	onRemove,
	onLeave,
}) => {
	const members = useGetManyByGroupId({ groupId: group.id })
	const [showRemoveMemberConfirmation, setShowRemoveMemberConfirmation] =
		useState(false)
	const [showLeaveGroupConfirmation, setShowLeaveGroupConfirmation] =
		useState(false)
	const [userToRemove, setUserToRemove] = useState<string | null>(null)
	const { showSnackbar } = useSnackbarContext()

	const handleRemoveGroupConfirmation = (profileId: string) => {
		setUserToRemove(profileId)
		setShowRemoveMemberConfirmation(true)
	}

	const assembleGroupEmail = () => {
		const emailBody = members?.data?.map((member) => {
			return `${member.full_name}<${member.email}>`
		})
		navigator.clipboard.writeText(emailBody?.join(',') || '')
		showSnackbar(
			'Copied emails to clipboard, paste into email app',
			'success'
		)
	}

	const assembleGroupSMS = () => {
		const smsBody = members?.data?.map((member) => {
			return `+${member.phone_number}`
		})
		navigator.clipboard.writeText(smsBody?.join(', ') || '')
		showSnackbar(
			'Copied phone numbers to clipboard, paste into text app',
			'success'
		)
	}

	const handleRemoveUser = (profileId: string) => {
		onRemove(profileId)
		setShowRemoveMemberConfirmation(false)
	}

	return (
		<>
			{isAdmin && (
				<div
					style={{
						textAlign: 'left',
						marginBottom: '1rem',
					}}
				>
					<Box
						style={{
							fontWeight: 'bold',
							fontSize: '1.0rem',
							marginBottom: '10px',
						}}
					>
						Invite Group Members by Link
					</Box>
					<Box style={{ marginBottom: '10px' }}>
						Paste the invite link into an email or text, send it to
						your friends, and when they click the link they&apos;ll
						automatically be added to this group.
					</Box>
					<InviteLink group={group} profile={currentUser} />
				</div>
			)}

			<Stack
				direction={'row'}
				justifyContent={'space-between'}
				alignItems={'center'}
				marginTop={'30px'}
			>
				<Typography fontWeight={'bold'}>Group Members</Typography>

				<Stack direction={'row'} spacing={2}>
					<IconButton onClick={assembleGroupEmail} color='primary'>
						<MailOutlineIcon />
					</IconButton>
					<IconButton onClick={assembleGroupSMS} color='primary'>
						<ChatBubbleOutlineIcon />
					</IconButton>
				</Stack>
			</Stack>
			<List>
				<Divider />
				{members?.data?.map((member) => (
					<GroupMemberItem
						key={member.id}
						member={member}
						adminId={group.admin}
						isAdmin={isAdmin}
						isCurrentUser={currentUser?.id === member.id}
						onRemove={(profileId: string) =>
							handleRemoveGroupConfirmation(profileId)
						}
						onLeave={() => setShowLeaveGroupConfirmation(true)}
					/>
				))}
			</List>

			<ConfirmationDialog
				isOpen={showRemoveMemberConfirmation}
				onClose={() => setShowRemoveMemberConfirmation(false)}
				onConfirm={() => handleRemoveUser(userToRemove!)}
				title={'Confirm Remove Member'}
				content={
					'Do you want to permanently remove this member from this group?'
				}
				confirmButtonText='Remove Member'
			/>

			<ConfirmationDialog
				isOpen={showLeaveGroupConfirmation}
				onClose={() => setShowLeaveGroupConfirmation(false)}
				onConfirm={() => onLeave(currentUser.id)}
				title={'Confirm Leave Group'}
				content={
					'Are you sure you want to leave this group? You will no longer be able to see any activities for this group.'
				}
				confirmButtonText='Leave Group'
			/>
		</>
	)
}

export default MembersTab
