import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router'
import ViewPoll from './ViewPoll'
import EditPoll from './EditPoll'
import {
	useGetPoll,
	loadPoll,
	useGetChoicesByPollId,
	useUpdatePoll,
	useUpdateChoice,
	useCreateChoice,
	useDeleteChoice,
	useDeletePoll,
	useDeleteChoicesByPollId,
} from 'api/polls'
import { Poll, PollChoice } from 'lib/supabase'
import { useUserContext } from 'contexts/UserContext'
import { v4 as uuidV4 } from 'uuid'
import { DateTime } from 'luxon'
import { useGroupsContext } from 'contexts/GroupsContext'
import { voteController } from 'utils'
import { useSnackbarContext } from 'contexts/SnackbarContext'
import { distributeNotifications } from 'utils'
import SendNotificationDialog from 'components/dialogs/SendNotificationDialog'

const PollPage: React.FC = () => {
	const navigate = useNavigate()
	const { profile, activeGroup } = useUserContext()
	const [editing, setEditing] = useState(false)
	const updatePoll = useUpdatePoll()
	const updateChoice = useUpdateChoice()
	const createChoice = useCreateChoice()
	const deleteChoice = useDeleteChoice()
	const deletePoll = useDeletePoll()
	const deleteChoicesByPollId = useDeleteChoicesByPollId()
	const { userIsAdminOfGroup } = useGroupsContext()
	const { showSnackbar } = useSnackbarContext()
	const [showSendMessage, setShowSendMessage] = useState(false)

	const { pollId } = useParams()
	const poll = useGetPoll({
		pollId: pollId!,
		options: { enabled: !!pollId },
	})

	const choices: PollChoice[] =
		useGetChoicesByPollId({
			pollId: pollId!,
			options: { enabled: !!pollId },
		}).data || []

	if (poll.isError) {
		console.error(poll.error)
	}

	useEffect(() => {
		let i = choices.length
		while (i < 6) {
			choices.push({
				id: '',
				created_at: '',
				poll_id: pollId || '',
				choice: '',
				votes: [],
				sort_order: null,
			})
			i++
		}
	}, [choices])

	const handleEdit = () => {
		setEditing(true)
	}

	const handleCancel = (dialogAction: string) => {
		console.log('PollPage::handleCancel: ' + dialogAction)
		if (dialogAction === 'delete' || dialogAction === 'new') {
			navigate('/polls')
		} else if (dialogAction === 'update') {
			setEditing(false)
		} else {
			setEditing(false)
		}
	}

	const handleSave = async (
		newPoll: Partial<Omit<Poll, 'id'>>,
		newChoices: PollChoice[]
	) => {
		// pad newChoices with empty choices
		let i = newChoices.length
		while (i < 6) {
			newChoices.push({
				id: '',
				created_at: '',
				poll_id: pollId || '',
				choice: '',
				votes: [],
				sort_order: null,
			})
			i++
		}

		// loop newChoices for what to do with each
		// id='' and choice != '' means new choice
		// id!='' and choice !='' means update choice
		// id!='' and choice ='' means delete choice
		newChoices.forEach(async (choice, index) => {
			if (choice.id === '' && choice.choice !== null && pollId) {
				const choiceId = uuidV4()
				newChoices[index].id = choiceId
				newChoices[index].poll_id = pollId
				newChoices[index].created_at = DateTime.now().toISO()
				newChoices[index].choice = choice.choice
				newChoices[index].votes = []
				await createChoice.mutateAsync({ choice: newChoices[index] })
			} else if (choice.id !== '' && choice.choice !== null) {
				await updateChoice.mutateAsync({
					choice: {
						...newChoices[index],
						id: newChoices[index].id,
					},
				})
			} else if (choice.id !== '' && choice.choice === null) {
				await deleteChoice.mutateAsync({ choiceId: choice.id })
			} else if (choice.id === '' && choice.choice === null) {
				// padded empty choice so do nothing
			}
		})

		if (poll.data && newPoll)
			await updatePoll.mutateAsync({
				poll: {
					...newPoll,
					id: poll.data.id,
				},
			})

		// setEditing(false)
	}

	const handleSendMessage = () => {
		if (!poll || !profile) return
		setShowSendMessage(true)
	}

	const handleResetPoll = async () => {
		choices.forEach(async (choice) => {
			choice.votes = []
			if (choice.id !== '') {
				await updateChoice.mutateAsync({
					choice: {
						...choice,
						id: choice.id,
					},
				})
			}
		})
	}

	const handleClosePoll = async () => {
		if (!poll.data) return
		await updatePoll.mutateAsync({
			poll: {
				...poll.data,
				status: 'closed',
			},
		})
	}

	const handleVote = (voteChoices: voteController[]) => {
		// users can only vote once so no check needed for prior votes
		voteChoices.forEach(async (vote) => {
			if (vote.checked) {
				const choice = choices.find((c) => c.id === vote.choice_id)
				if (choice && profile) {
					choice.votes.push(profile.id)
					await updateChoice.mutateAsync({ choice })
				}
			}
		})
	}

	const handleDelete = async () => {
		if (!poll.data) return
		await deleteChoicesByPollId.mutateAsync({ pollId: poll?.data?.id })
		await deletePoll.mutateAsync({ pollId: poll?.data?.id })
	}

	const onBack = () => {
		navigate('/polls')
	}

	const handleSendNotifications = async (
		selected: string[],
		message: string,
		type: string
	) => {
		if (!poll.data || !profile || !activeGroup) return

		console.log(
			'PollPage::handleSendNotifications - send notifications to selected members for action type ' +
				type
		)
		distributeNotifications(
			'poll',
			type,
			selected,
			message,
			profile.full_name,
			activeGroup?.id,
			poll.data.id
		)

		showSnackbar('Notifications sent to selected members', 'success')
		if (type === 'message') setShowSendMessage(false)
		if (type === 'update') setEditing(false)
		if (type === 'delete') navigate('/polls')
	}

	return (
		<main>
			{editing ? (
				<EditPoll
					poll={poll.data as Poll}
					choices={choices as PollChoice[]}
					onSave={handleSave}
					onCancel={(dialogAction: string) =>
						handleCancel(dialogAction)
					}
					onDelete={handleDelete}
					onSendNotifications={handleSendNotifications}
				></EditPoll>
			) : (
				<ViewPoll
					poll={poll.data as Poll}
					choices={choices as PollChoice[]}
					onBack={onBack}
					canViewButtons={
						poll?.data?.poll_owner_id === profile?.id ||
						userIsAdminOfGroup(poll?.data?.group_id ?? '')
					}
					onEdit={handleEdit}
					onDelete={handleDelete}
					onSendMessage={handleSendMessage}
					onVote={handleVote}
					onReset={handleResetPoll}
					onClose={handleClosePoll}
					onSendNotifications={handleSendNotifications}
				></ViewPoll>
			)}
			<SendNotificationDialog
				isOpen={showSendMessage}
				onCancel={() => setShowSendMessage(false)}
				activeGroup={activeGroup?.id}
				profileId={profile?.id}
				selectAll={true}
				forceSendAll={true}
				title='Send Message'
				textFieldLabel='Message to Send'
				showConfirm={false}
				showConfirmText=''
				confirmOnly={false}
				dialogType='notify'
				dialogAction='message'
				objectType='poll'
				onUpdateDeleteAction={() => {}}
				onSendNotifications={(selected: string[], message: string) => {
					handleSendNotifications(selected, message, 'message')
				}}
			/>
		</main>
	)
}

export default PollPage
