import React, { useEffect, useState } from 'react'
import {
	Box,
	Divider,
	Stack,
	Typography,
	Paper,
	Avatar,
	AvatarGroup,
	IconButton,
	CircularProgress,
} from '@mui/material'
import { getLatestMessage } from 'api/messages/getManyByGroupId'
import { useGetManyByGroupId } from 'api/profile'
import useConversations from 'hooks/useConversations'
import { getManyByGroupId as getGroupMembers, getProfile } from 'api/profile'
import { useUserContext } from 'contexts/UserContext'
import { DateTime } from 'luxon'
import { useNavigate } from 'react-router'
import type { Profile } from 'lib/supabase'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { stringAvatar } from 'utils'
import { set } from 'react-hook-form'

type PrivateChat = {
	chatId: string
	members: string[]
	latestMessage: string
}

const Messages: React.FC = () => {
	const navigate = useNavigate()
	const { profile, activeGroup } = useUserContext()
	// const [members, setMembers] = useState<Profile[]>([])
	const [privateChats, setPrivateChats] = useState<PrivateChat[]>([])
	const [loadingPrivateChats, setLoadingPrivateChats] = useState(true)

	const { data: members } = useGetManyByGroupId({
		groupId: activeGroup?.id || '',
	})

	const handleNavigateToGroupChat = () => {
		navigate(`/chat/${profile?.active_group}`)
	}

	const handleNavigateToPrivateChat = (chatId: string) => {
		navigate(`/chat/${profile?.active_group}/${chatId}`)
	}

	// get latest group chat message for the group
	const [latestGroupMessage, setLatestGroupMessage] = useState<string>('')

	useEffect(() => {
		const fetchLatestGroupMessage = async () => {
			const resp = await getLatestMessage({
				groupId: profile?.active_group || '',
			})
			setLatestGroupMessage(resp ? resp[0].created_at : '')
		}

		if (profile?.active_group) {
			fetchLatestGroupMessage()
		}
	}, [profile?.active_group])

	// get private chats for current user
	const { data: chats } = useConversations({
		userId: profile?.id || '',
		groupId: profile?.active_group || '',
	})

	// For each private chat:
	// 1) get the full names for each participant
	// 2) get the latest message
	useEffect(() => {
		if (!chats) return

		const fetchPrivateChats = async () => {
			const privateChatsPromises = chats.map(async (chat) => {
				// Find members that match the participants
				const privateChatMembers =
					members
						?.filter((member) =>
							chat.participants.includes(member.id)
						)
						.map((member) => member.full_name) || []

				const resp = await getLatestMessage({
					chatId: chat.id,
				})
				const latestMessage = resp ? resp[0].created_at : ''

				return {
					chatId: chat.id,
					members: privateChatMembers,
					latestMessage: latestMessage,
				}
			})

			const resolvedPrivateChats = await Promise.all(privateChatsPromises)
			setPrivateChats(resolvedPrivateChats)
		}

		fetchPrivateChats()
		setLoadingPrivateChats(false)
	}, [chats, members])

	console.log('latestGroupMessage', latestGroupMessage)

	return (
		<Box style={{ paddingBottom: '60px' }}>
			<>
				<Paper
					elevation={3}
					style={{ margin: '10px', padding: '10px' }}
				>
					<div onClick={handleNavigateToGroupChat}>
						<Typography
							fontWeight={1000}
							fontSize={'1.0rem'}
							textTransform={'uppercase'}
						>
							Group Conversation
						</Typography>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'space-between',
							}}
						>
							<AvatarGroup
								max={10}
								spacing={12}
								style={{
									display: 'flex',
									justifyContent: 'left',
									paddingTop: '10px',
								}}
							>
								{members &&
									members.map((member) => (
										<Avatar
											key={member.full_name}
											style={{
												width: 36,
												height: 36,
												fontSize: '1.0rem',
												textTransform: 'uppercase',
											}}
											{...stringAvatar(member.full_name)}
										/>
									))}
							</AvatarGroup>
							<IconButton size='large'>
								<KeyboardArrowRightIcon />
							</IconButton>
						</div>
						<Typography
							fontSize={'0.7rem'}
							color={'#666666'}
							fontStyle={'italic'}
							padding={'5px 0px 0px 10px'}
						>
							{latestGroupMessage === ''
								? 'No messages yet'
								: 'Latest message posted ' +
								  DateTime.fromISO(
										latestGroupMessage
								  ).toRelative()}
						</Typography>
					</div>
				</Paper>

				<Paper
					elevation={3}
					style={{ margin: '10px', padding: '10px' }}
				>
					<Typography
						fontWeight={1000}
						fontSize={'1.0rem'}
						textTransform={'uppercase'}
					>
						Private Conversations
					</Typography>
					<Typography
						fontSize={'0.7rem'}
						fontStyle={'italic'}
						color={'#666666'}
						paddingLeft={'10px'}
						paddingBottom={'10px'}
					>
						These conversations are only visible to the
						participants.
					</Typography>
					{loadingPrivateChats ? (
						<Stack
							direction={'row'}
							gap={2}
							justifyContent={'start'}
							alignItems={'center'}
						>
							<CircularProgress size={30} />
							<Typography
								fontStyle={'italic'}
								fontSize={'0.9rem'}
							>
								Loading private conversations...
							</Typography>
						</Stack>
					) : (
						privateChats &&
						privateChats.map((chat) => (
							<div
								key={chat.chatId}
								onClick={() =>
									handleNavigateToPrivateChat(chat.chatId)
								}
							>
								<Divider />
								<Stack direction={'column'}>
									<Stack
										direction={'row'}
										justifyContent={'space-between'}
										paddingTop={'10px'}
									>
										<AvatarGroup
											max={10}
											spacing={12}
											style={{
												display: 'flex',
												justifyContent: 'left',
											}}
										>
											{chat.members.map((participant) => (
												<Avatar
													key={participant}
													style={{
														width: 36,
														height: 36,
														fontSize: '1.0rem',
														textTransform:
															'uppercase',
													}}
													{...stringAvatar(
														participant
													)}
												/>
											))}
										</AvatarGroup>
										<IconButton
											size='large'
											style={{ padding: '0px' }}
										>
											<KeyboardArrowRightIcon />
										</IconButton>
									</Stack>
									<Typography
										fontSize={'0.7rem'}
										color={'#666666'}
										fontStyle={'italic'}
										padding={'5px 0px 5px 10px'}
									>
										{chat.latestMessage === ''
											? 'No messages yet'
											: 'Latest message posted ' +
											  DateTime.fromISO(
													chat.latestMessage
											  ).toRelative()}
									</Typography>
								</Stack>
							</div>
						))
					)}
				</Paper>
			</>
		</Box>
	)
}

export default Messages
