import React from 'react'
import { Typography, Button } from '@mui/material'

type Props = {
	paymentUrlLoading: boolean
	onGetBillingLink: () => void
}

export const ExpiredManageAccount: React.FC<Props> = ({
	paymentUrlLoading,
	onGetBillingLink,
}) => {
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				textAlign: 'center',
			}}
		>
			<div style={{}}>
				<Typography fontWeight={500} fontSize={'1.2rem'}>
					We&apos;d love to have you back!
				</Typography>
				<Typography paddingTop={'10px'}>
					To re-subscribe, simply click the button.
				</Typography>
			</div>
			<div
				style={{
					marginTop: '20px',
				}}
			>
				<Button
					disabled={paymentUrlLoading}
					variant='contained'
					color='success'
					onClick={onGetBillingLink}
				>
					{paymentUrlLoading ? 'Loading...' : 'Subscribe Now'}
				</Button>
			</div>
		</div>
	)
}
