import React from 'react'
import { Typography, Stack, Skeleton } from '@mui/material'

export const LoadingSubscription: React.FC = () => {
	return (
		<div
			style={{
				width: '100%',
				textAlign: 'left',
				margin: '00px 20px',
			}}
		>
			<Typography fontStyle={'italic'} fontWeight={500}>
				Loading Subscription Info...
			</Typography>
			<Stack direction='row' spacing={2}>
				<Skeleton animation='wave' height={33} width={'67%'} />
				<Skeleton animation='wave' height={33} width={'33%'} />
			</Stack>
			<Stack direction='row' spacing={2}>
				<Skeleton animation='wave' height={33} width={'67%'} />
				<Skeleton animation='wave' height={33} width={'33%'} />
			</Stack>
			<Stack direction='row' spacing={2}>
				<Skeleton animation='wave' height={33} width={'67%'} />
				<Skeleton animation='wave' height={33} width={'33%'} />
			</Stack>
		</div>
	)
}
