import { yupResolver } from '@hookform/resolvers/yup'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Validators } from 'utils/validators'
import { object } from 'yup'
import { FormInputText } from '../elements'
import { Button, Stack, Autocomplete, TextField, AppBar } from '@mui/material'
import timezones from 'utils/timezones'
import { DateTime } from 'luxon'

export type GroupFormValues = {
	name: string
	activityName: string
	defaultTimezone: string
}

type Props = {
	defaultValues?: GroupFormValues
	disabled?: boolean
	source: string
	onSubmit: (data: GroupFormValues) => void
	onCancel?: () => void
}
const GroupForm: React.FC<Props> = ({
	defaultValues,
	disabled,
	source,
	onSubmit,
	onCancel,
}) => {
	const [defaultTimezone, setDefaultTimezone] = useState(
		DateTime.local().zoneName || null
	)

	const { handleSubmit, control } = useForm<GroupFormValues>({
		resolver: yupResolver(
			object().shape({
				name: Validators.NAME.required(),
				activityName: Validators.ACTIVITY_NAME.required(),
				defaultTimezone: Validators.DEFAULT_TIMEZONE.required(),
			})
		),
		defaultValues: defaultValues || {
			name: '',
			activityName: '',
			defaultTimezone: DateTime.local().zoneName || '',
		},
	})

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Stack gap={4} marginTop={'40px'}>
				<FormInputText
					label='Group Name'
					name='name'
					variant='outlined'
					control={control}
					required
					disabled={disabled}
				/>
				<FormInputText
					label='Activity Name'
					name='activityName'
					variant='outlined'
					control={control}
					required
					disabled={disabled}
				/>
				<Autocomplete
					disablePortal
					options={timezones}
					disabled
					onChange={(
						event: React.SyntheticEvent,
						newValue: string | null
					) => {
						setDefaultTimezone(newValue)
					}}
					value={defaultTimezone || DateTime.local().zoneName || null}
					renderInput={(params) => (
						<TextField
							{...params}
							name='defaultTimezone'
							label='Timezone'
							variant='outlined'
							fullWidth
						/>
					)}
				/>
			</Stack>

			<AppBar
				position='fixed'
				sx={{
					top: 'auto',
					bottom: 0,
					background: '#ffffff',
					paddingBottom: '60px',
				}}
			>
				<Stack
					direction='row'
					spacing={2}
					marginRight={'20px'}
					paddingTop={'20px'}
					justifyContent={'right'}
					alignItems={'right'}
				>
					{source === 'welcome' ? (
						<Button variant='contained' type='submit'>
							Create Group
						</Button>
					) : (
						<>
							<Button variant='text' onClick={onCancel}>
								Cancel
							</Button>
							<Button variant='contained' type='submit'>
								Save
							</Button>
						</>
					)}
				</Stack>
			</AppBar>
		</form>
	)
}

export default GroupForm
