import React, { useState, useRef } from 'react'
import { useForm } from 'react-hook-form'
import {
	FormInputDate,
	FormInputNumber,
	FormInputSelect,
	FormInputText,
	FormInputTime,
} from '../elements'
import { IconButton, MenuItem, Stack, Typography, Button } from '@mui/material'
import { Venue } from 'lib/supabase'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import QuickAddVenueDialog from '../../dialogs/QuickAddVenueDialog'
import { Validators } from 'utils/validators'
import { object } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

export type ActivityFormValues = {
	name: string
	venue: string
	date: string // ISO string
	time: string // ISO string
	note?: string | null
	url?: string
	maxSpots: number
}

type Props = {
	id?: string
	venues: Venue[]
	defaultValues: ActivityFormValues
	hideNoteField?: boolean
	hideLinkField?: boolean
	onSubmit: (data: ActivityFormValues) => void
	onQuickAddVenue: (venue: Venue) => Promise<Venue | null>
}
const ActivityForm = ({
	id,
	venues,
	defaultValues,
	hideNoteField,
	hideLinkField,
	onSubmit,
	onQuickAddVenue,
}: Props) => {
	const [venueQuickAddOpen, setVenueQuickAddOpen] = React.useState(false)
	const { handleSubmit, control, setValue, getValues, resetField } =
		useForm<ActivityFormValues>({
			resolver: yupResolver(
				object().shape({
					name: Validators.ACTIVITY_NAME.required(),
					venue: Validators.UUID.required(),
					date: Validators.DATE.required(),
					time: Validators.TIME.required(),
					note: Validators.ACTIVITY_NOTE,
					url: Validators.URL,
					maxSpots: Validators.NUMBER.min(0).required(),
					sendNotification: Validators.BOOLEAN,
				})
			),
			defaultValues: defaultValues,
		})

	const [characterCount, setCharacterCount] = useState(0)
	const handleQuickAddVenue = async (venue: Venue) => {
		const newVenue = await onQuickAddVenue(venue)

		if (newVenue) {
			setValue('venue', newVenue.id)
		}
	}

	const countCharacters = () => {
		const count = getValues('note')?.length || 0
		setCharacterCount(count)
	}

	const clearNote = () => {
		resetField('note')
		setValue('note', '')
		setCharacterCount(0)
	}

	return (
		<>
			<form
				id={id || 'edit-form'}
				onSubmit={handleSubmit(onSubmit)}
				style={{
					padding: '2rem',
				}}
			>
				<FormInputText
					name='name'
					label='Activity Name'
					control={control}
					autoComplete='off'
					required
				/>
				<Stack
					direction='row'
					spacing={2}
					alignItems='center'
					justifyContent='center'
					paddingTop={2}
				>
					<FormInputSelect
						name='venue'
						label='Venue'
						control={control}
						required
						disabled={venues.length === 0}
					>
						{venues.map((venue) => (
							<MenuItem key={venue.id} value={venue.id}>
								{venue.name}
							</MenuItem>
						))}
					</FormInputSelect>
					<IconButton
						color='success'
						onClick={() => setVenueQuickAddOpen(true)}
					>
						<AddCircleIcon />
					</IconButton>
				</Stack>
				{/* Date time stack */}
				<Stack
					direction='row'
					spacing={2}
					justifyContent='center'
					alignItems='center'
					paddingY={2}
				>
					<FormInputDate
						name='date'
						label='Activity Date'
						control={control}
					/>

					<FormInputTime
						name='time'
						label='Activity Time'
						control={control}
					/>
				</Stack>
				{/* Note & Link */}
				<Stack direction='column' gap={2}>
					{!hideNoteField && (
						<div>
							<FormInputText
								name='note'
								label='Activity Note'
								control={control}
								multiline
								minRows={6}
								variant='outlined'
								onKeyUp={countCharacters}
							/>
							<div
								style={{
									minHeight: '35px',
								}}
							>
								<div
									style={{
										justifyContent: 'space-between',
										display:
											characterCount > 0
												? 'flex'
												: 'none',
									}}
								>
									<Typography
										fontSize={'0.8rem'}
										fontStyle={'italic'}
										paddingLeft={'10px'}
										paddingTop={'5px'}
										style={{
											color:
												characterCount > 900
													? '#d32f2f'
													: 'inherit',
										}}
									>
										{characterCount
											.toString()
											.replace(
												/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
												','
											)}{' '}
										of 1,000 characters used
									</Typography>
									<Button size='small' onClick={clearNote}>
										Clear
									</Button>
								</div>
							</div>
						</div>
					)}

					<FormInputText
						name='url'
						label='Link URL'
						control={control}
						variant='outlined'
					/>
					{/* max spots */}
					<FormInputNumber
						name='maxSpots'
						label='Max Spots'
						control={control}
						type='number'
						inputProps={{
							type: 'numeric',
							pattern: '[0-9]*',
						}}
					/>
				</Stack>
			</form>

			<QuickAddVenueDialog
				open={venueQuickAddOpen}
				setOpen={setVenueQuickAddOpen}
				onSubmit={handleQuickAddVenue}
			/>
		</>
	)
}

export default ActivityForm
