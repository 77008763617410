import { Button, Stack } from '@mui/material'
import { useGroupsContext } from 'contexts/GroupsContext'
import { useSnackbarContext } from 'contexts/SnackbarContext'
import { useUserContext } from 'contexts/UserContext'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { DEFAULT_ERROR_MESSAGE } from 'utils'

type Props = {
	groupId: string
}
const Join: React.FC<Props> = ({ groupId }) => {
	const navigate = useNavigate()
	const { profile, setActiveGroup, updateProfile } = useUserContext()
	const { addUserToGroup } = useGroupsContext()
	const { showSnackbar } = useSnackbarContext()
	const [buttonsDisabled, setButtonsDisabled] = React.useState(() => !profile)

	useEffect(() => {
		if (profile) setButtonsDisabled(false)
	}, [profile])

	const handleJoin = async () => {
		if (!profile) return
		try {
			await addUserToGroup({
				userId: profile.id,
				groupId: groupId,
			}).then(() => {
				// using `then` here to delay the navigate until after the call has finished
				// set the user's active group and mark the welcome flow as completed
				setActiveGroup(groupId).then(() => {
					updateProfile({
						viewed_welcome: true,
					}).then(() => {
						navigate('/')
					})
				})
			})
		} catch (err) {
			if (err instanceof Error) {
				console.error(err.message)
			}
			showSnackbar(DEFAULT_ERROR_MESSAGE, 'error')
		}
	}

	const handleDecline = () => {
		navigate('/')
	}

	return (
		<Stack
			justifyContent='center'
			alignItems='center'
			direction='column'
			gap={2}
		>
			<Button
				disabled={buttonsDisabled}
				fullWidth
				variant='contained'
				color='success'
				style={{ maxWidth: '200px' }}
				onClick={handleJoin}
			>
				Join
			</Button>
			<Button
				disabled={buttonsDisabled}
				fullWidth
				variant='text'
				style={{ maxWidth: '200px' }}
				onClick={handleDecline}
			>
				Decline
			</Button>
		</Stack>
	)
}

export default Join
