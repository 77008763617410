import React, { useEffect } from 'react'
import { Typography, Box } from '@mui/material'
import { useSnackbarContext } from 'contexts/SnackbarContext'
import { useUserContext } from 'contexts/UserContext'
import GroupForm, { GroupFormValues } from 'components/forms/prebuilt/GroupForm'
import { DEFAULT_ERROR_MESSAGE } from 'utils'
import { v4 as uuidV4 } from 'uuid'
import { Group } from 'lib/supabase'
import { DateTime } from 'luxon'
import { useGroupsContext } from 'contexts/GroupsContext'
import ActionComplete from 'components/ActionComplete'

type Props = {
	setNextEnabled: (val: boolean) => void
}
const CreateGroup: React.FC<Props> = ({ setNextEnabled }) => {
	const { profile, setActiveGroup } = useUserContext()
	const { createGroup, addUserToGroup } = useGroupsContext()
	const { showSnackbar } = useSnackbarContext()
	const [hasActiveGroup, setHasActiveGroup] = React.useState(() => {
		if (profile?.active_group) {
			return true
		}
		return false
	})

	const handleCreateGroup = async (data: GroupFormValues) => {
		if (!profile) throw new Error('No profile found')

		try {
			const newGroup: Group = {
				id: uuidV4(),
				name: data.name,
				activity_name: data.activityName,
				admin: profile.id,
				created_at: DateTime.now().toISO(),
				status: 'active',
				updated_at: null,
				default_timezone: DateTime.local().zoneName,
			}

			await createGroup(newGroup)
			await addUserToGroup({ userId: profile.id, groupId: newGroup.id })
			await setActiveGroup(newGroup.id)

			setHasActiveGroup(true)
		} catch (err) {
			if (err instanceof Error) {
				console.error(err.message)
			}
			setHasActiveGroup(false)
			showSnackbar(DEFAULT_ERROR_MESSAGE, 'error')
		}
	}

	useEffect(() => {
		setNextEnabled(hasActiveGroup)
	}, [hasActiveGroup])

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				padding: '20px 40px',
			}}
		>
			<Typography
				fontWeight={'bold'}
				fontSize={'1.2rem'}
				paddingBottom={2}
			>
				Create Your Group
			</Typography>
			<div style={{ display: hasActiveGroup ? 'none' : 'block' }}>
				<Typography paddingBottom={1}>
					Next step is to create your first group.
				</Typography>
				<Typography>
					You will create your first activity and invite your friends
					after you create a group.
				</Typography>
			</div>

			<Box sx={{ my: 2, px: 0 }} width={'100%'}>
				{hasActiveGroup ? (
					<>
						<ActionComplete message='Successfully created group!' />
						<Typography paddingTop={4}>
							Tap <b>Next</b> to continue setting up your Joyner
							account.
						</Typography>
					</>
				) : (
					<GroupForm
						disabled={hasActiveGroup}
						onSubmit={handleCreateGroup}
						source='welcome'
						defaultValues={{
							name: '',
							activityName: 'Activity',
							defaultTimezone: DateTime.local().zoneName || '',
						}}
					/>
				)}
			</Box>
		</div>
	)
}

export default CreateGroup
