import React from 'react'
import {
	IconButton,
	Button,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Divider,
	AppBar,
} from '@mui/material'
import AppHeader from '../../components/AppHeader'
import { useNavigate } from 'react-router'
import { Venue } from '../../lib/supabase'
import Loading from '../../components/Loading'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useGetManyByGroupId } from 'api/venue'
import { useUserContext } from 'contexts/UserContext'
import ErrorComponent from 'components/ErrorComponent'

type Props = {
	headless?: boolean | false
}

const VenuesTab: React.FC<Props> = (headless) => {
	const { activeGroup } = useUserContext()
	const venues = useGetManyByGroupId({
		groupId: activeGroup?.id || '',
		options: { enabled: !!activeGroup?.id },
	})
	const navigate = useNavigate()

	if (venues.isError) {
		console.error(venues.error)
	}

	const handleListClick = (venue?: Venue) => {
		if (venue) navigate(`/venue/${venue?.id}`)
	}

	return (
		<>
			{!headless && (
				<AppHeader
					leftComponent={
						<IconButton
							size='large'
							edge='start'
							style={{ color: 'white' }}
							onClick={() => navigate('-1')}
						>
							<ArrowBackIcon />
						</IconButton>
					}
					title='Venues'
				/>
			)}

			<>
				{venues.isSuccess ? (
					<List>
						<Divider />
						{venues.data.map((venue: Venue) => (
							<div key={venue.id}>
								<ListItem
									disableGutters
									onClick={() => handleListClick(venue)}
									secondaryAction={
										<Button size='small' variant='outlined'>
											View
										</Button>
									}
								>
									<ListItemButton>
										<ListItemText primary={venue.name} />
									</ListItemButton>
								</ListItem>
								<Divider />
							</div>
						))}
					</List>
				) : venues.isError ? (
					<ErrorComponent />
				) : (
					<Loading />
				)}
			</>

			<AppBar
				position='fixed'
				sx={{
					top: 'auto',
					bottom: 0,
					background: '#ffffff',
					paddingBottom: '60px',
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						gap: '5px',
						alignItems: 'center',
						justifyContent: 'center',
						marginTop: '10px',
					}}
				>
					<Button
						variant='contained'
						color='success'
						fullWidth
						style={{ maxWidth: '300px' }}
						onClick={() => navigate('/venue/new')}
					>
						Create New Venue
					</Button>
				</div>
			</AppBar>
		</>
	)
}

export default VenuesTab
