import { Typography, Paper } from '@mui/material'
import React from 'react'

const InviteExpired: React.FC = () => {
	return (
		<Paper elevation={3} sx={{ padding: '20px 20px' }}>
			<Typography align='center'>
				Invite has <strong>expired</strong>, please contact the group
				admin.
			</Typography>
		</Paper>
	)
}

export default InviteExpired
