import { sendServerRequest } from '../vercel/apicalls'

export type SubscriptionInfo = {
	status: string
	trialing: boolean
	trialend: number
	periodstart: number
	periodend: number
	autorenew: boolean
	subscription_id: string
}

export type StripeData = {
	stripeinfo: object
	subscription: object
}

export type EmbeddedCheckoutData = {
	sessionId: string
	clientSecret: string
	session: object
}

export const normalizeSubscriptionStatus = (status: string) => {
	let retStatus = 'inactive'
	if (['active', 'trialing'].indexOf(status) > -1) retStatus = 'active'
	if (
		[
			'canceled',
			'unpaid',
			'past_due',
			'paused',
			'incomplete',
			'incomplete_expired',
		].indexOf(status) > -1
	)
		retStatus = 'expired'
	return retStatus
}

export const getSubscriptionInfo = async (sid: string) => {
	let furl = import.meta.env.VITE_VERCELAPI_HOST + 'stripe/get-subscription'
	furl += '?cid=' + sid
	const data: StripeData = await sendServerRequest('GET', furl)
	return data
}

export const getEmbeddedCheckout = async (
	type: string,
	email: string,
	cid: string,
	pid: string
) => {
	let furl =
		import.meta.env.VITE_VERCELAPI_HOST + 'stripe/get-embedded-checkout'
	furl += '?type=' + encodeURIComponent(type)
	furl += '&email=' + encodeURIComponent(email)
	furl += '&cid=' + encodeURIComponent(cid)
	furl += '&pid=' + encodeURIComponent(pid)
	console.log('stripe-utils::getEmbeddedCheckout', furl)
	const data: EmbeddedCheckoutData = await sendServerRequest('POST', furl)
	return data
}
