import React, { useState } from 'react'
import { Autocomplete, AppBar, Button, TextField } from '@mui/material'
import timezones from 'utils/timezones'
import { DateTime } from 'luxon'
import { FormInputText } from 'components/forms/elements'
import { Group, Profile } from '../../lib/supabase'
import { object } from 'yup'
import { Validators } from 'utils/validators'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog'

type PropertiesTabProps = {
	group: Group
	onDeleteGroup: (groupId: string) => void
	onUpdateGroup: (updatedGroup: FormValues, defaultTimezone: string) => void
}

type FormValues = {
	groupName: string
	activityName: string
}

const PropertiesTab: React.FC<PropertiesTabProps> = ({
	group,
	onDeleteGroup,
	onUpdateGroup,
}) => {
	const [defaultTimezone, setDefaultTimezone] = useState(
		group.default_timezone
	)
	const [editing, setEditing] = useState(false)
	const [showDeleteGroupConfirmation, setShowDeleteGroupConfirmation] =
		useState(false)

	const handleUpdateGroup = (
		updatedGroup: FormValues,
		defaultTimezone: string
	) => {
		onUpdateGroup(updatedGroup, defaultTimezone)
		setEditing(false)
	}

	const { handleSubmit, control } = useForm<FormValues>({
		resolver: yupResolver(
			object().shape({
				groupName: Validators.STRING.required(),
				activityName: Validators.ACTIVITY_NAME.required(),
			})
		),
		defaultValues: {
			groupName: group.name,
			activityName: group.activity_name || 'Activity',
		},
	})

	return (
		<>
			<form
				id='group-update-form'
				onSubmit={handleSubmit((updatedGroup: FormValues) =>
					handleUpdateGroup(updatedGroup, defaultTimezone || '')
				)}
			>
				<FormInputText
					name='groupName'
					label='Group Name'
					control={control}
					variant={editing ? 'outlined' : 'standard'}
					disabled={!editing}
					fullWidth
					required
					sx={{ marginTop: '1rem' }}
				/>
				<FormInputText
					name='activityName'
					label='Activity Type Name'
					control={control}
					disabled={!editing}
					variant={editing ? 'outlined' : 'standard'}
					fullWidth
					helperText='Ex: Game, Tee Time, Book Club, etc - default is Activity'
					sx={{ margin: '25px 0' }}
				/>
				<Autocomplete
					disablePortal
					disabled={!editing}
					options={timezones}
					onChange={(
						event: React.SyntheticEvent,
						newValue: string | null
					) => {
						setDefaultTimezone(newValue)
					}}
					value={group.default_timezone || DateTime.local().zoneName}
					renderInput={(params) => (
						<TextField
							{...params}
							label='Timezone'
							variant={editing ? 'outlined' : 'standard'}
							fullWidth
						/>
					)}
				/>
			</form>

			<div style={{ marginTop: '20px' }}></div>
			<AppBar
				position='fixed'
				sx={{
					top: 'auto',
					bottom: 0,
					background: '#ffffff',
					paddingBottom: '20px',
				}}
			>
				<div
					style={{
						flexDirection: 'column',
						gap: '10px',
						alignItems: 'center',
						margin: '10px 40px',
						display: editing ? 'none' : 'flex',
					}}
				>
					<Button
						fullWidth
						style={{
							maxWidth: '300px',
						}}
						variant='contained'
						onClick={() => setEditing(true)}
					>
						Edit Group Properties
					</Button>

					<Button
						variant='outlined'
						color='error'
						fullWidth
						style={{ maxWidth: '300px' }}
						onClick={() => setShowDeleteGroupConfirmation(true)}
					>
						Delete Group
					</Button>
				</div>
				<div
					style={{
						flexDirection: 'row',
						gap: '10px',
						alignItems: 'right',
						justifyContent: 'right',
						margin: '10px 40px',
						display: editing ? 'flex' : 'none',
					}}
				>
					<Button onClick={() => setEditing(false)}>Cancel</Button>
					<Button
						type='submit'
						form='group-update-form'
						variant='contained'
					>
						Save
					</Button>
				</div>
			</AppBar>

			<ConfirmationDialog
				isOpen={showDeleteGroupConfirmation}
				onClose={() => setShowDeleteGroupConfirmation(false)}
				onConfirm={() => onDeleteGroup(group.id)}
				title={'Confirm Delete Group'}
				content={'Do you want to permanently delete this group?'}
				confirmButtonText='Delete Group'
			/>
		</>
	)
}

export default PropertiesTab
