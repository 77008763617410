import React, { useEffect } from 'react'
import { useGetInvite } from 'api/invites/get'
import Loading from 'components/Loading'
import { useParams } from 'react-router'
import AnonHeader from 'components/AnonHeader'
import { Box, Typography, Paper } from '@mui/material'
import { useAuthContext } from 'contexts/AuthContext'
import AccountInfo from './AccountInfo'
import Join from './Join'
import { DateTime } from 'luxon'
import InviteExpired from './InviteExpired'
import { ErrorComponent } from 'components/ErrorComponent'

const InvitePage = () => {
	const { isAuthenticated } = useAuthContext()
	const { inviteId } = useParams()
	const [isExpired, setIsExpired] = React.useState<boolean>()

	if (!inviteId) throw new Error('No inviteId provided')

	const invite = useGetInvite({
		inviteId,
		options: { enabled: !!inviteId },
	})

	if (invite.isError) {
		console.error(invite.error)
	}

	useEffect(() => {
		if (invite.isSuccess) {
			const now = DateTime.now()
			const expires = DateTime.fromISO(invite.data.expires_at)
			setIsExpired(expires < now)
		}
	}, [invite.isSuccess])

	return (
		<>
			{invite.isSuccess ? (
				<>
					<AnonHeader />
					<Paper
						elevation={3}
						sx={{
							padding: '20px 40px',
							margin: '10px',
							backgroundColor: '#e8f5e9',
							color: '#1b5e20',
						}}
					>
						<Typography paddingTop='10px' align='center'>
							You have been invited by{' '}
							<b>{invite.data.created_by_name}</b> to join the
							Joyner group...
						</Typography>
						<Typography
							align='center'
							fontWeight='bold'
							paddingTop={'10px'}
						>
							{invite.data.group_name}
						</Typography>
					</Paper>

					<Box padding='1rem 1rem'>
						{isExpired ? (
							<InviteExpired />
						) : (
							<>
								{isAuthenticated ? (
									<Join groupId={invite.data.group_id} />
								) : (
									<AccountInfo />
								)}
							</>
						)}
					</Box>
				</>
			) : invite.isError ? (
				<ErrorComponent />
			) : (
				<Loading />
			)}
		</>
	)
}

export default InvitePage
