import { Poll } from '../../lib/supabase'
import { AppBar, Stack, Button } from '@mui/material'
import React from 'react'

type Props = {
	poll: Poll
	visible: boolean
	onEditClick: () => void
	onDeleteClick: () => void
	onSendClick: () => void
	onViewResultsClick: () => void
}
const PollButtons: React.FC<Props> = ({
	poll,
	visible,
	onEditClick,
	onDeleteClick,
	onSendClick,
	onViewResultsClick,
}) => {
	if (visible) {
		return (
			<AppBar
				position='fixed'
				sx={{
					top: 'auto',
					bottom: 0,
					background: '#ffffff',
					paddingBottom: '40px',
				}}
			>
				<Stack
					direction={'column'}
					spacing={1}
					marginTop={'5px'}
					marginX={'15%'}
				>
					<Button
						onClick={onDeleteClick}
						variant='contained'
						color='error'
						style={{
							display:
								poll.status === 'closed' ? 'block' : 'none',
						}}
					>
						Delete Poll
					</Button>

					<Button
						style={{
							display:
								poll.status === 'closed' ? 'none' : 'block',
						}}
						onClick={onEditClick}
						variant='contained'
					>
						Edit Poll
					</Button>
					{/* <Button variant='outlined' onClick={onViewResultsClick}>
						View Results
					</Button> */}
					<Button onClick={onSendClick} variant='outlined'>
						Send Message
					</Button>
				</Stack>
			</AppBar>
		)
	} else {
		return null
	}
}

export default PollButtons
