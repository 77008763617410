import {
	ToggleButtonGroup,
	ToggleButton,
	AppBar,
	Button,
	IconButton,
	Paper,
	Badge,
	Fab,
	Stack,
	Typography,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import React from 'react'
import useRouteMatch from 'hooks/useRouteMatch'
import { useNavigate } from 'react-router'

type Props = {
	pollBadge: boolean
	messageBadge: boolean
}

const TopNav: React.FC<Props> = ({ pollBadge, messageBadge }) => {
	const navigate = useNavigate()
	const routeMatch = useRouteMatch(['/', '/polls', '/messages'])
	const [currentTab, setCurrentTab] = React.useState(
		routeMatch?.pattern?.path
	)
	const [showPollBadge, setShowPollBadge] = React.useState(false)
	const [showMessageBadge, setShowMessageBadge] = React.useState(false)
	// console.log('currentTab:', currentTab)

	React.useEffect(() => {
		setShowPollBadge(pollBadge)
		setShowMessageBadge(messageBadge)
	}, [pollBadge, messageBadge])

	const handleTopNavClick = (
		event: React.MouseEvent<HTMLElement>,
		newTab: string
	) => {
		if (newTab === null) return
		setCurrentTab(newTab)
		navigate(newTab)
	}

	const handleBottomClick = () => {
		if (currentTab === '/') {
			navigate('/create/activity')
		} else if (currentTab === '/messages') {
			navigate('/create/conversation')
		} else if (currentTab === '/polls') {
			navigate('/create/poll')
		}
	}

	return (
		<>
			<div
				id='topNav'
				color='primary'
				style={{
					display: 'flex',
					backgroundColor: '#333333',
					position: 'sticky',
					top: '100px',
					width: '100%',
					padding: '0px 0px 10px 0px',
					zIndex: 1000,
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<div
					style={{
						width: '95%',
						marginTop: '2px',
						marginBottom: '2px',
					}}
				>
					<ToggleButtonGroup
						value={currentTab}
						onChange={handleTopNavClick}
						exclusive
						fullWidth
						size='medium'
						data-intro='Tap different tabs to view more info for your group.'
					>
						<ToggleButton
							href=''
							value='/'
							style={{
								lineHeight: '1.0rem',
								padding: '8px 11px',
								fontSize: '0.9rem',
								fontWeight:
									currentTab === '/' ? 'bold' : 'normal',
								backgroundColor:
									currentTab === '/' ? '#ffffff' : '#212121',
								color:
									currentTab === '/' ? '#000000' : '#ffffff',
							}}
						>
							Activities
						</ToggleButton>
						<ToggleButton
							href=''
							value='/messages'
							style={{
								lineHeight: '1.0rem',
								padding: '8px 11px',
								fontSize: '0.9rem',
								fontWeight:
									currentTab === '/messages'
										? 'bold'
										: 'normal',
								backgroundColor:
									currentTab === '/messages'
										? '#ffffff'
										: '#212121',
								color:
									currentTab === '/messages'
										? '#000000'
										: '#ffffff',
							}}
						>
							Messages
						</ToggleButton>
						<ToggleButton
							href=''
							value='/polls'
							style={{
								lineHeight: '1.0rem',
								padding: '8px 11px',
								fontSize: '0.9rem',
								fontWeight:
									currentTab === '/polls' ? 'bold' : 'normal',
								backgroundColor:
									currentTab === '/polls'
										? '#ffffff'
										: '#212121',
								color:
									currentTab === '/polls'
										? '#000000'
										: '#ffffff',
							}}
						>
							Polls
						</ToggleButton>
					</ToggleButtonGroup>
				</div>
			</div>
			<AppBar position='fixed' sx={{ top: 'auto', bottom: 0 }}>
				<Paper
					elevation={3}
					style={{
						height: '50px',
						paddingTop: '10px',
						paddingLeft: '20%',
						paddingRight: '20%',
						paddingBottom: '10px',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						// backgroundColor: '#9e9e9e',
					}}
				>
					{/* <Stack
						direction='column'
						spacing={0}
						justifyContent={'center'}
						alignItems={'center'}
					>
						<Button
							size='large'
							startIcon={<AddIcon />}
							style={{ display: 'block' }}
						>
							<Typography fontSize={'0.8rem'}>
								Settings
							</Typography>
						</Button>
					</Stack> */}
					<Fab
						color='success'
						aria-label='add'
						style={{ position: 'relative', top: '-24px' }}
						onClick={handleBottomClick}
						data-intro='Tap the Create New button to create new items for your group.'
					>
						<AddIcon />
					</Fab>
					{/* <IconButton size='large'>
						<AddIcon />
					</IconButton> */}

					{/* <Button
						onClick={handleCreateNewActivity}
						variant='contained'
						color='success'
						fullWidth
						style={{
							display: currentTab === '/' ? 'block' : 'none',
						}}
					>
						Create New Activity
					</Button>
					<Button
						onClick={handleCreateNewConversation}
						variant='contained'
						color='success'
						fullWidth
						style={{
							display:
								currentTab === '/messages' ? 'block' : 'none',
						}}
					>
						Create New Conversation
					</Button>
					<Button
						onClick={handleCreateNewPoll}
						variant='contained'
						color='success'
						fullWidth
						style={{
							display: currentTab === '/polls' ? 'block' : 'none',
						}}
					>
						Create New Poll
					</Button> */}
				</Paper>
			</AppBar>
		</>
	)
}

export default TopNav
