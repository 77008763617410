import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { useMachine } from '@xstate/react'
import messageFlowMachine, {
	FlowSteps,
	WELCOME_STEP_COOKIE,
} from './messageFlowMachine'
import { type Profile } from 'lib/supabase'
import WelcomeWizardNav from './WelcomeWizardNav'
import { Snapshot } from 'xstate'
import ErrorComponent from 'components/ErrorComponent'
import Loading from 'components/Loading'
import CreateActivity from './FlowSteps/CreateActivity'
import CreateGroup from './FlowSteps/CreateGroup'
import InviteMembers from './FlowSteps/InviteMembers'
import SaveToHomescreen from './FlowSteps/SaveToHomescreen'
import StartFreeTrial from './FlowSteps/StartFreeTrial'
import Match from './Match'
import { useUserContext } from 'contexts/UserContext'

type Props = {
	headerRef: React.RefObject<HTMLDivElement | undefined>
	profile: Profile
	// setShowBottomNav: (show: boolean) => void
	setShowTopNav: (show: boolean) => void
	setSettingsDisabled: (disabled: boolean) => void
}
const Welcome: React.FC<Props> = ({
	headerRef,
	profile,
	// setShowBottomNav,
	setShowTopNav,
	setSettingsDisabled,
}) => {
	const { updateProfile } = useUserContext()
	const [flowMachine] = useState(messageFlowMachine)
	if (flowMachine.error || flowMachine.machine == null) {
		console.error(flowMachine.error)

		// remove the cookie that was causing errors
		localStorage.removeItem(WELCOME_STEP_COOKIE)
		return <ErrorComponent />
	}

	const [persistedSnapshot] = useState(() => {
		// load the state of the machine from localStorage
		const cookieValue = localStorage.getItem(WELCOME_STEP_COOKIE)
		if (cookieValue != null) {
			return JSON.parse(cookieValue) as Snapshot<unknown>
		}

		return undefined
	})

	const [current, send, actorRef] = useMachine(flowMachine.machine, {
		input: {
			profile,
		},
		snapshot: persistedSnapshot,
	})

	const [nextEnabled, setNextEnabled] = useState(true)

	useEffect(() => {
		const sub = actorRef.subscribe(async (state) => {
			if (state.value === FlowSteps.Error) {
				// remove the cookie that was causing errors
				localStorage.removeItem(WELCOME_STEP_COOKIE)
				return
			}

			if (state.value === FlowSteps.Tour) {
				// when we reach the tour step, that means the welcome flow is complete
				await updateProfile({
					viewed_welcome: true,
				})

				// setShowBottomNav(true)
				setShowTopNav(true)
			}

			// store the state of the machine in localStorage for persistence
			const persistedSnapshot = actorRef.getPersistedSnapshot()
			localStorage.setItem(
				WELCOME_STEP_COOKIE,
				JSON.stringify(persistedSnapshot)
			)
		})

		return () => {
			sub.unsubscribe()
		}
	}, [flowMachine])

	useEffect(() => {
		// setShowBottomNav(false)
		setShowTopNav(false)
		setSettingsDisabled(true)
	}, [])

	const handleNext = () => send({ type: 'next' })

	const handleBack = () => send({ type: 'back' })

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				height: `calc(100vh - ${
					headerRef?.current?.clientHeight || 100
				}px)`,
			}}
		>
			<Box
				sx={{
					width: '100%',
					height: '100%',
				}}
			>
				<Match current={current} states={[FlowSteps.SaveToHome]}>
					<SaveToHomescreen setNextEnabled={setNextEnabled} />
				</Match>

				<Match current={current} states={[FlowSteps.Trial]}>
					<StartFreeTrial setNextEnabled={setNextEnabled} />
				</Match>

				<Match current={current} states={[FlowSteps.CreateGroup]}>
					<CreateGroup setNextEnabled={setNextEnabled} />
				</Match>

				{/* <Match current={current} states={[FlowSteps.CreateActivity]}>
					<CreateActivity />
				</Match> */}

				<Match current={current} states={[FlowSteps.InviteMembers]}>
					<InviteMembers />
				</Match>

				<Match current={current} states={[FlowSteps.Tour]}>
					<Loading />
				</Match>

				<Match current={current} states={[FlowSteps.Error]}>
					<ErrorComponent />
				</Match>
			</Box>

			{current.context.showNav && (
				<WelcomeWizardNav
					hasBack={current.context.hasBack}
					nextEnabled={nextEnabled}
					onNext={handleNext}
					onBack={handleBack}
				/>
			)}
		</Box>
	)
}

export default Welcome
