import React, { useState, useEffect, useRef } from 'react'
import type { Profile } from 'lib/supabase'
import {
	Button,
	Box,
	Typography,
	AppBar,
	Avatar,
	Paper,
	Stack,
	styled,
} from '@mui/material'
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from '@mui/material'
import FilerobotImageEditor, { TABS, TOOLS } from 'react-filerobot-image-editor'
import { getCurrentImgDataFunction } from 'react-filerobot-image-editor'
import { useUserContext } from 'contexts/UserContext'
import AppHeader from 'components/AppHeader'
import { set } from 'react-hook-form'

type Props = {
	readonly profile: Profile
	onSave: (newProfile: Partial<Omit<Profile, 'id'>>) => void
	onCancel: () => void
}

const VisuallyHiddenInput = styled('input')({
	clip: 'rect(0 0 0 0)',
	clipPath: 'inset(50%)',
	height: 1,
	overflow: 'hidden',
	position: 'absolute',
	bottom: 0,
	left: 0,
	whiteSpace: 'nowrap',
	width: 1,
})

const EditAvatar: React.FC<Props> = ({ profile, onSave, onCancel }) => {
	const {
		updateProfile,
		getAvatarUrl,
		uploadScratchFile,
		deleteScratchFile,
		getPublicUrl,
		uploadNewAvatar,
	} = useUserContext()
	const [avatarUrl, setAvatarUrl] = useState<string>('')
	const [origAvatarUrl, setOrigAvatarUrl] = useState<string>('')
	const [scratchUrl, setScratchUrl] = useState<string>('')
	const [initials, setInitials] = useState<string>('')
	const [showAvatarEditDialog, setShowAvatarEditDialog] = useState(false)

	// const editorRef = useRef()
	const editorRef = useRef<getCurrentImgDataFunction | null>(null)

	useEffect(() => {
		const fetchAvatarUrl = async () => {
			if (profile) {
				const url = await getAvatarUrl(profile.id + '.jpeg')
				setAvatarUrl(url)
				setOrigAvatarUrl(url)
			}
		}

		const fetchUserInitials = () => {
			const names = profile?.full_name.split(' ')
			const firstInitial = names[0].slice(0, 1)
			const lastInitial = names[names.length - 1].slice(0, 1)
			setInitials(firstInitial + lastInitial)
		}

		fetchAvatarUrl()
		fetchUserInitials()
	}, [profile])

	const handleClearAvatar = async () => {
		setAvatarUrl('')
	}

	const handleResetAvatar = async () => {
		setAvatarUrl(origAvatarUrl)
	}

	const handleScratchUpload = async (files: FileList | null) => {
		if (files?.[0]) {
			const resp = await uploadScratchFile(files[0])
			if (resp) {
				const publicUrl = await getPublicUrl(resp)
				setScratchUrl(publicUrl)
				setShowAvatarEditDialog(true)
			}
		} else {
			console.error('EditAvatar::handleUpload: No file available')
		}
	}

	const wipeScratch = async () => {
		const filename = scratchUrl.split('/').pop() || ''
		if (scratchUrl) {
			await deleteScratchFile(filename)
		}
	}

	const onNewAvatar = async () => {
		const fileName = scratchUrl.split('/').pop() || ''
		const fileType = fileName.split('.').pop() || ''
		if (editorRef.current) {
			const editedImage = editorRef.current({
				name: fileName,
				extension: fileType,
			}).imageData
			if (editedImage.imageBase64) {
				await handleSaveNewAvatar({
					imageBase64: editedImage.imageBase64,
				})
			} else {
				console.error('No edited image available')
			}
		}
	}

	const handleSaveNewAvatar = (editedImageObject: {
		imageBase64: string
	}) => {
		const newFile = base64ToFile(
			editedImageObject.imageBase64,
			profile.id + '.jpeg'
		)
		uploadNewAvatar(newFile).then((newAvatarPath) => {
			setAvatarUrl(newAvatarPath)
			setShowAvatarEditDialog(false)
			setScratchUrl('')
			wipeScratch()
		})
	}

	const base64ToFile = (base64String: string, fileName: string): File => {
		const byteString = atob(base64String.split(',')[1])
		const mimeString = base64String
			.split(',')[0]
			.split(':')[1]
			.split(';')[0]

		const ab = new ArrayBuffer(byteString.length)
		const ia = new Uint8Array(ab)
		for (let i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i)
		}

		const blob = new Blob([ab], { type: mimeString })
		return new File([blob], fileName, { type: mimeString })
	}

	const handleDialogClose = () => {
		setShowAvatarEditDialog(false)
	}

	return (
		<>
			<Box flexGrow={1}>
				<AppHeader
					leftComponent={
						<Button sx={{ color: '#0195f5' }} onClick={onCancel}>
							Cancel
						</Button>
					}
					title={'Update Avatar'}
				/>

				{/* PREVIEW AVATAR w/ ACTIONS */}
				<Paper
					elevation={3}
					style={{ margin: '10px', padding: '10px 20px' }}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							gap: '10px',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<Avatar
							src={avatarUrl}
							style={{ width: '96px', height: '96px' }}
						>
							{initials}
						</Avatar>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								gap: '10px',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<Button
								variant='text'
								fullWidth
								disabled={!avatarUrl}
								style={{ maxWidth: '250px' }}
								onClick={handleClearAvatar}
							>
								Clear
							</Button>
							<Button
								variant='text'
								fullWidth
								style={{ maxWidth: '250px' }}
								onClick={handleResetAvatar}
							>
								Reset
							</Button>
						</div>
					</div>
				</Paper>

				{/* EDIT NEW AVATAR IMAGE */}
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: '10px',
						justifyContent: 'center',
						alignItems: 'center',
						marginTop: '20px',
					}}
				></div>
			</Box>
			<AppBar
				position='fixed'
				sx={{
					top: 'auto',
					bottom: 0,
					background: '#ffffff',
					paddingBottom: '60px',
				}}
			>
				<div
					style={{
						paddingTop: '20px',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						gap: '10px',
					}}
				>
					<Button
						component='label'
						role={undefined}
						variant='contained'
						fullWidth
						style={{ maxWidth: '250px' }}
					>
						Select New Avatar Image
						<VisuallyHiddenInput
							type='file'
							accept='image/jpeg, image/png, image/jpg'
							onChange={(event) =>
								handleScratchUpload(event.target.files)
							}
						/>
					</Button>
				</div>
			</AppBar>

			<Dialog
				open={showAvatarEditDialog}
				onClose={handleDialogClose}
				fullWidth
			>
				<DialogTitle>Change Avatar</DialogTitle>
				<DialogContent>
					{scratchUrl !== '' && (
						<FilerobotImageEditor
							source={scratchUrl}
							onBeforeSave={() => false}
							getCurrentImgDataFnRef={editorRef!}
							onSave={(editedImageObject) => {
								if (editedImageObject.imageBase64) {
									handleSaveNewAvatar({
										imageBase64:
											editedImageObject.imageBase64,
									})
								}
							}}
							showCanvasOnly={true}
							defaultSavedImageType='jpeg'
							defaultSavedImageQuality={1.0}
							savingPixelRatio={100}
							previewPixelRatio={100}
							avoidChangesNotSavedAlertOnLeave={true}
							removeSaveButton
							observePluginContainerSize
						/>
					)}
				</DialogContent>
				<DialogActions>
					<Button variant='outlined' onClick={handleDialogClose}>
						Cancel
					</Button>
					<Button variant='contained' onClick={onNewAvatar}>
						Save
					</Button>
				</DialogActions>
			</Dialog>
		</>
	)
}

export default EditAvatar
