import { Profile } from 'lib/supabase'
import { assign, setup } from 'xstate'

export enum FlowSteps {
	SaveToHome = 'save-to-home',
	Trial = 'trial',
	CreateGroup = 'create-group',
	// CreateActivity = 'create-activity',
	InviteMembers = 'invite-members',
	Tour = 'tour',
	Error = 'error',
}

enum Guards {
	hasGroup = 'hasGroup',
}

type Context = {
	profile: Profile
	hasBack: boolean
	showNav: boolean
}

type Input = {
	profile: Profile
}

export const WELCOME_STEP_COOKIE = 'joyner-welcomeStep'

const messageFlowMachine = () => {
	try {
		return {
			error: undefined,
			machine: setup({
				types: {
					context: {} as Context,
					input: {} as Input,
				},
				guards: {
					[Guards.hasGroup]: ({ context }) => {
						return context.profile?.active_group != null
					},
				},
			}).createMachine({
				id: 'messageFlow',
				initial: FlowSteps.SaveToHome,
				context: ({ input: { profile } }) => ({
					profile,
					hasBack: false,
					showNav: true,
				}),
				states: {
					[FlowSteps.SaveToHome]: {
						entry: [
							assign({
								showNav: true,
							}),
						],
						exit: [
							assign({
								hasBack: false,
							}),
						],
						on: {
							next: [
								{
									guard: Guards.hasGroup,
									target: FlowSteps.Tour,
								},
								{
									target: FlowSteps.Trial,
								},
							],
							'*': {
								target: FlowSteps.Error,
							},
						},
					},
					[FlowSteps.Trial]: {
						entry: [
							assign({
								showNav: true,
								hasBack: false,
							}),
						],
						exit: [
							assign({
								hasBack: false,
							}),
						],
						on: {
							next: {
								target: FlowSteps.CreateGroup,
							},
							// back: {
							// 	target: FlowSteps.SaveToHome,
							// },
						},
					},
					[FlowSteps.CreateGroup]: {
						entry: [
							assign({
								showNav: true,
							}),
						],
						exit: [
							assign({
								hasBack: false,
							}),
						],
						on: {
							next: {
								target: FlowSteps.InviteMembers,
							},
						},
					},
					// [FlowSteps.CreateActivity]: {
					// 	entry: [
					// 		assign({
					// 			showNav: true,
					// 		}),
					// 	],
					// 	exit: [
					// 		assign({
					// 			hasBack: false,
					// 		}),
					// 	],
					// 	on: {
					// 		next: {
					// 			target: FlowSteps.InviteMembers,
					// 		},
					// 		back: {
					// 			target: FlowSteps.CreateGroup,
					// 		},
					// 	},
					// },
					[FlowSteps.InviteMembers]: {
						entry: [
							assign({
								showNav: true,
							}),
						],
						exit: [
							assign({
								hasBack: false,
							}),
						],
						on: {
							next: {
								target: FlowSteps.Tour,
							},
							// back: {
							// 	target: FlowSteps.CreateActivity,
							// },
						},
					},
					[FlowSteps.Tour]: {
						type: 'final',
						entry: [
							assign({
								showNav: false,
							}),
						],
					},
					[FlowSteps.Error]: {
						type: 'final',
						entry: [
							assign({
								showNav: false,
							}),
						],
					},
				},
			}),
		}
	} catch (error) {
		return {
			error: error,
			machine: undefined,
		}
	}
}

export default messageFlowMachine
