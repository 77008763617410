import React from 'react'
import {
	Typography,
	Stack,
	Button,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
} from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

type Props = {
	paymentUrlLoading: boolean
	onGetBillingLink: () => void
}

export const Unsubscribed: React.FC<Props> = ({
	paymentUrlLoading,
	onGetBillingLink,
}) => {
	return (
		<div>
			{/* NO SUBSCRIPTION CTA */}

			<div style={{ textAlign: 'center' }}>
				<Typography fontSize={'1.2rem'} fontWeight={'bold'}>
					Subscribe to Joyner for just $24/year
				</Typography>
				<Typography paddingTop={'0.4rem'}>
					Subscription includes 90 day FREE trial
				</Typography>
				<Typography paddingTop={'0.4rem'}>
					Billed $6 every three months
				</Typography>
			</div>

			<div style={{ marginTop: '20px' }}>
				<div>
					<Typography paddingTop={'1.0rem'} fontWeight={'bold'}>
						Your subscription includes:
					</Typography>
					<List style={{ paddingTop: '5px' }}>
						<ListItem disablePadding>
							<ListItemIcon
								style={{
									color: 'green',
									justifyContent: 'center',
								}}
							>
								<CheckCircleIcon />
							</ListItemIcon>
							<ListItemText
								primary='90 day FREE trial'
								secondary=''
							/>
						</ListItem>
						<ListItem disablePadding>
							<ListItemIcon
								style={{
									color: 'green',
									justifyContent: 'center',
								}}
							>
								<CheckCircleIcon />
							</ListItemIcon>
							<ListItemText
								primary='Unlimited Groups'
								secondary=''
							/>
						</ListItem>
						<ListItem disablePadding>
							<ListItemIcon
								style={{
									color: 'green',
									justifyContent: 'center',
								}}
							>
								<CheckCircleIcon />
							</ListItemIcon>
							<ListItemText
								primary='Unlimited Group Activities'
								secondary=''
							/>
						</ListItem>
						<ListItem disablePadding>
							<ListItemIcon
								style={{
									color: 'green',
									justifyContent: 'center',
								}}
							>
								<CheckCircleIcon />
							</ListItemIcon>
							<ListItemText
								primary='Unlimited Group Members'
								secondary='Group members use Joyner for FREE'
							/>
						</ListItem>
					</List>
					<div style={{ marginTop: '20px' }}>
						<Stack direction='row' spacing={1}>
							<Typography fontWeight={'bold'} fontSize={'3.0rem'}>
								$24
							</Typography>
							<div style={{ paddingTop: '10px' }}>
								<Stack direction='column' spacing={0}>
									<Typography>per</Typography>
									<Typography>year</Typography>
								</Stack>
							</div>
							<div
								style={{
									marginLeft: '40px',
									marginTop: '15px',
								}}
							>
								<Button
									disabled={paymentUrlLoading}
									variant='contained'
									color='success'
									onClick={onGetBillingLink}
								>
									{paymentUrlLoading
										? 'Loading...'
										: 'Subscribe Now'}
								</Button>
							</div>
						</Stack>
					</div>
					<Typography fontSize={'0.8rem'} fontStyle={'italic'}>
						Billing and subscriptions are managed in Stripe
					</Typography>
				</div>
			</div>
		</div>
	)
}
