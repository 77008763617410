import React, { useState, useEffect } from 'react'
import { getProfile } from '../../api/profile'
import type { Message, Profile } from 'lib/supabase'
import {
	Box,
	Typography,
	Avatar,
	Stack,
	Popover,
	IconButton,
} from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import ActivityLinkBox from './ActivityLinkBox'
import { MessagePopover, ActivityPopover, ContactPopover } from './Popovers'
import { stringAvatar } from 'utils'

type Props = {
	message: Message
	userIsAuthor: boolean
	currentUserId: string
	userIsAdmin: boolean
	onDeleteMessage: (messageId: string) => void
}

const Bubble = React.forwardRef<Element | undefined, Props>(
	(
		{ message, userIsAuthor, currentUserId, userIsAdmin, onDeleteMessage },
		ref
	) => {
		const [profile, setProfile] = useState<Profile | null>(null)

		useEffect(() => {
			const fetchProfile = async () => {
				const profileData = await getProfile({
					profileId: message.sender,
				})
				setProfile(profileData)
			}
			fetchProfile()
		}, [message.sender])

		const [contactAnchorEl, setContactAnchorEl] =
			useState<null | HTMLElement>(null)
		const [activityAnchorEl, setActivityAnchorEl] =
			useState<null | HTMLElement>(null)
		const [messageAnchorEl, setMessageAnchorEl] =
			useState<null | HTMLElement>(null)

		const handleContactPopoverClick = (
			event: React.MouseEvent<HTMLElement>
		) => {
			setContactAnchorEl(event.currentTarget)
		}

		const handleActivityLinkClick = (
			event: React.MouseEvent<HTMLElement>
		) => {
			setActivityAnchorEl(event.currentTarget)
		}

		const handleMessagePopoverClick = (
			event: React.MouseEvent<HTMLElement>
		) => {
			setMessageAnchorEl(event.currentTarget)
		}

		const handleClose = () => {
			setContactAnchorEl(null)
			setActivityAnchorEl(null)
			setMessageAnchorEl(null)
		}

		const handleCopy = (contentToCopy: string | '') => {
			if (content) navigator.clipboard.writeText(contentToCopy)
			setContactAnchorEl(null)
			setActivityAnchorEl(null)
			setMessageAnchorEl(null)
		}

		const handleDelete = async (messageId: string) => {
			console.log('delete message with messageId ' + messageId)
			onDeleteMessage(messageId)
			setMessageAnchorEl(null)
		}

		const messageBody = (
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: userIsAuthor ? 'flex-end' : 'flex-start',
					paddingLeft: userIsAuthor ? '0' : '0.3rem',
					paddingRight: userIsAuthor ? '0.3rem' : '0',
					paddingY: '0.3rem',
				}}
			>
				<Box
					sx={{
						width: '90%',
						textAlign: userIsAuthor ? 'right' : 'left',
						backgroundColor: userIsAuthor ? '#90caf9' : '#ECEFF1',
						padding: '0.5rem 0.7rem',
						borderRadius: userIsAuthor
							? '10px 0px 10px 10px'
							: '0px 10px 10px 10px',
					}}
				>
					{userIsAuthor ? (
						// LOGGED IN USER BUBBLE
						<Stack direction={'column'} gap={1}>
							<Stack
								direction={'row'}
								gap={1}
								justifyContent={'space-between'}
								alignItems={'flex-start'}
							>
								<Typography
									textAlign={'left'}
									paddingY={'5px'}
									onClick={handleMessagePopoverClick}
								>
									{message.body}
								</Typography>
								<IconButton onClick={handleMessagePopoverClick}>
									<MoreVertIcon />
								</IconButton>
							</Stack>
							{message.activity_id != null ? (
								<div onClick={handleActivityLinkClick}>
									<ActivityLinkBox
										activityId={message.activity_id}
										authorBox={
											message.sender === currentUserId
										}
									/>
								</div>
							) : (
								<> </>
							)}
						</Stack>
					) : (
						// EVERYONE ELSE BUBBLE
						<Stack direction={'row'} gap={1}>
							<Avatar onClick={handleContactPopoverClick}>
								{profile?.full_name && (
									<Avatar
										{...stringAvatar(profile.full_name)}
									/>
								)}
							</Avatar>
							<Stack direction={'column'} gap={1} width={'100%'}>
								<Stack
									direction={'row'}
									gap={1}
									justifyContent={'space-between'}
									alignItems={'flex-start'}
								>
									<Typography
										textAlign={'left'}
										paddingY={'5px'}
										onClick={handleMessagePopoverClick}
									>
										{message.body}
									</Typography>
									<IconButton
										onClick={handleMessagePopoverClick}
									>
										<MoreVertIcon />
									</IconButton>
								</Stack>

								{message.activity_id != null ? (
									<div onClick={handleActivityLinkClick}>
										<ActivityLinkBox
											activityId={message.activity_id}
											authorBox={
												message.sender === currentUserId
											}
										/>
									</div>
								) : (
									<> </>
								)}
							</Stack>
						</Stack>
					)}
				</Box>

				<Popover
					id='contact-popover'
					anchorEl={contactAnchorEl}
					open={Boolean(contactAnchorEl)}
					onClose={handleClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
				>
					{profile && (
						<ContactPopover
							contact={profile}
							onCopy={(contentToCopy) =>
								handleCopy(contentToCopy)
							}
							onCancel={handleClose}
						/>
					)}
				</Popover>

				<Popover
					id='activity-popover'
					anchorEl={activityAnchorEl}
					open={Boolean(activityAnchorEl)}
					onClose={handleClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
				>
					{message.activity_id && (
						<ActivityPopover
							activityId={message.activity_id}
							onCopy={(contentToCopy) =>
								handleCopy(contentToCopy)
							}
							onCancel={handleClose}
						/>
					)}
				</Popover>

				<Popover
					id='message-popover'
					anchorEl={messageAnchorEl}
					open={Boolean(messageAnchorEl)}
					onClose={handleClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
				>
					<MessagePopover
						message={message}
						profile={profile}
						userIsAuthor={userIsAuthor}
						userIsAdmin={userIsAdmin}
						onCopy={(contentToCopy) => handleCopy(contentToCopy)}
						onDelete={(messageId) => handleDelete(messageId)}
						onCancel={handleClose}
					/>
				</Popover>
			</Box>
		)

		// for triggering the new message fetch
		const content = ref ? (
			<Box component={'article'} ref={ref}>
				{messageBody}
			</Box>
		) : (
			<Box component={'article'}>{messageBody}</Box>
		)

		return content
	}
)

// I have no idea why this is needed
// but eslint throws a fit if I don't add this
Bubble.displayName = 'ChatBubble'

export default Bubble
