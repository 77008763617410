import React from 'react'
import { Box, Typography } from '@mui/material'
import hero from 'images/joyner-logo.svg'

const AnonHeader = () => {
	return (
		<Box
			style={{
				textAlign: 'center',
				background: '#333333',
				paddingTop: '20px',
				paddingBottom: '20px',
				width: '100%',
			}}
			component={'header'}
		>
			<img
				alt='hero'
				src={hero}
				style={{ width: '60%', maxWidth: '600px' }}
			/>
			<Typography
				color={'#ffffff'}
				fontStyle={'italic'}
				style={{ paddingTop: '20px' }}
			>
				Group calendars made SIMPLE!
			</Typography>
		</Box>
	)
}

export default AnonHeader
