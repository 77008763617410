import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { Box, IconButton } from '@mui/material'
import AppHeader from '../../components/AppHeader'
import { useUserContext } from '../../contexts/UserContext'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { track } from '@vercel/analytics'
import { useSnackbarContext } from 'contexts/SnackbarContext'
import {
	SubscriptionInfo,
	StripeData,
	getSubscriptionInfo,
} from 'vercel/stripe-utils'
import { TrialManageAccount } from './TrialManageAccount'
import { TrialSubscriptionDetails } from './TrialSubscriptionDetails'
import { PaidSubscriptionDetails } from './PaidSubscriptionDetails'
import { ExpiredManageAccount } from './ExpiredManageAccount'
import { LoadingSubscription } from './LoadingSubsciption'
import { PaidManageAccount } from './PaidManageAccount'
import { Unsubscribed } from './Unsubscribed'
import { ExpiredSubscriptionDetails } from './ExpiredSubscriptionDetails'
import { AboutStripe } from './AboutStripe'
import { StripeEmbeddedCheckoutDialog } from 'components/dialogs/StripeEmbeddedCheckoutDialog'

export default function Account() {
	const { profile } = useUserContext()
	const navigate = useNavigate()
	const { showSnackbar } = useSnackbarContext()
	const [paymentUrlLoading, setPaymentUrlLoading] = useState(false)
	const [stripeAccountLoading, setStripeAccountLoading] = useState(true)
	const [subscription, setSubscription] = useState<SubscriptionInfo | null>(
		null
	)
	const [showCheckout, setShowCheckout] = useState(false)

	const goBack = () => {
		navigate(-1)
	}

	const handleStripeCheckout = async () => {
		// if (!profile) return

		// setPaymentUrlLoading(true)
		// let url = ''
		// if (profile?.subscription_status === 'inactive') {
		// 	url = import.meta.env.VITE_STRIPE_PAYMENT_LINK_URL
		// }
		// if (profile?.subscription_status === 'expired') {
		// 	url = import.meta.env.VITE_STRIPE_PAYMENT_LINK_POSTTRIAL
		// }

		// const paymentUrl = url + '?prefilled_email=' + profile.email
		// if (import.meta.env.PROD) {
		// 	window.open(paymentUrl, '_self')
		// } else {
		// 	window.open(paymentUrl, '_blank')
		// }

		setShowCheckout(true)
	}

	const handleStripeAccount = async () => {
		const billingURL = import.meta.env.VITE_STRIPE_BILLING_URL
		let stripePortalURL = billingURL
		if (profile?.email)
			stripePortalURL += '?prefilled_email=' + profile?.email
		window.open(stripePortalURL, '_blank')

		// const resp = await getSubscriptionEmbed()
		// console.log('resp', resp.session)
		// setShowCheckout(true)
	}

	useEffect(() => {
		async function getData() {
			if (profile && profile.stripe_customer_id) {
				const data: StripeData = await getSubscriptionInfo(
					profile.stripe_customer_id
				)
				return data.subscription as SubscriptionInfo
			}
		}

		if (
			(profile?.subscription_status === 'active' ||
				profile?.subscription_status === 'expired') &&
			profile?.stripe_customer_id
		) {
			getData().then((sub: SubscriptionInfo | undefined) => {
				if (sub) {
					setSubscription(sub)
					setStripeAccountLoading(false)
				} else {
					showSnackbar('Unable to load subscription data', 'error')
				}
			})
		} else {
			setStripeAccountLoading(false)
		}
	}, [profile])

	return (
		<>
			<Box>
				<AppHeader
					leftComponent={
						<div style={{ textAlign: 'center', width: '50px' }}>
							<IconButton
								size='large'
								edge='start'
								sx={{ color: 'white' }}
								onClick={goBack}
							>
								<ArrowBackIcon />
							</IconButton>
						</div>
					}
					title='Account'
				/>

				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						margin: '20px 10px 0px 10px',
					}}
				>
					{/* UNSUBSCRIBED NEW USER */}
					{profile?.subscription_status === 'inactive' && (
						<Unsubscribed
							paymentUrlLoading={paymentUrlLoading}
							onGetBillingLink={handleStripeCheckout}
						/>
					)}

					{/* SKELETON WHILE LOADING */}
					{stripeAccountLoading && <LoadingSubscription />}

					{/* SUBSCRIBED USER w/ FREE TRIAL */}
					{subscription &&
						profile?.subscription_status === 'active' &&
						subscription.trialing && (
							<>
								<TrialSubscriptionDetails
									profile={profile}
									subscription={subscription}
								/>
								<TrialManageAccount
									onGetBillingLink={handleStripeCheckout}
								/>
							</>
						)}

					{/* SUBSCRIBED USER w/ PAID ACCOUNT */}
					{subscription &&
						profile?.subscription_status === 'active' &&
						!subscription.trialing && (
							<>
								<PaidSubscriptionDetails
									profile={profile}
									subscription={subscription}
								/>
								<PaidManageAccount
									onGetBillingLink={handleStripeAccount}
								/>
							</>
						)}

					{/* USER w/ EXPIRED SUBSCRIPTION */}
					{subscription &&
						profile?.subscription_status === 'expired' && (
							<>
								<ExpiredSubscriptionDetails
									profile={profile}
									subscription={subscription}
								/>
								<ExpiredManageAccount
									paymentUrlLoading={paymentUrlLoading}
									onGetBillingLink={handleStripeCheckout}
								/>
							</>
						)}

					{/* ABOUT STRIPE */}
					<AboutStripe />
				</div>
			</Box>

			{/* STRIPE EMBEDDED CHECKOUT */}
			<StripeEmbeddedCheckoutDialog
				isOpen={showCheckout}
				onClose={() => setShowCheckout(false)}
				type='paid'
				email={profile?.email || ''}
				customerId={profile?.stripe_customer_id || ''}
				profileId={profile?.id || ''}
			/>
		</>
	)
}
