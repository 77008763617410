import React from 'react'
import type { Profile } from 'lib/supabase'
import {
	Button,
	IconButton,
	Switch,
	FormGroup,
	FormControlLabel,
	Typography,
	AppBar,
} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { formatPhoneNumber } from 'utils/funcs'
import AppHeader from 'components/AppHeader'
import UserAvatar from 'components/UserAvatar'

type Props = {
	profile: Profile
	onBack: () => void
	onEdit: () => void
	onAvatarEdit: () => void
}

const ViewProfile: React.FC<Props> = ({
	profile,
	onBack,
	onEdit,
	onAvatarEdit,
}) => {
	return (
		<>
			<AppHeader
				leftComponent={
					<IconButton
						size='large'
						edge='start'
						sx={{ color: 'white' }}
						onClick={onBack}
					>
						<ArrowBackIcon />
					</IconButton>
				}
				title={'Profile'}
			/>
			<div style={{ margin: '40px 0px 0px 40px' }}>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						paddingBottom: '20px',
					}}
				>
					<UserAvatar
						profile={profile}
						style={{ width: '96px', height: '96px' }}
						onClick={() => {}}
					/>
				</div>
				<div style={{ paddingBottom: '20px' }}>
					<div style={{ fontSize: '0.8rem', fontWeight: 'bold' }}>
						Name
					</div>
					<div>{profile.full_name}</div>
				</div>
				<div style={{ paddingBottom: '20px' }}>
					<div style={{ fontSize: '0.8rem', fontWeight: 'bold' }}>
						Email
					</div>
					<div>{profile.email ?? 'No email'}</div>
				</div>

				<div style={{ width: '60%' }}>
					<div style={{ fontSize: '0.8rem', fontWeight: 'bold' }}>
						Mobile Phone
					</div>
					<div>
						{profile.phone_number
							? formatPhoneNumber(profile.phone_number)
							: 'No phone number'}
					</div>
				</div>
				<div>
					<div style={{ paddingTop: '20px' }}>
						<div
							style={{
								fontSize: '0.8rem',
								fontWeight: 'bold',
							}}
						>
							Notification Preferenes
						</div>
					</div>
					<FormGroup>
						<FormControlLabel
							control={
								<Switch
									checked={profile.allow_email}
									disabled
								/>
							}
							label='Allow Email Notifications'
						/>
					</FormGroup>

					<FormGroup>
						<FormControlLabel
							control={
								<Switch checked={profile.allow_sms} disabled />
							}
							label='Allow SMS Notifications'
						/>
					</FormGroup>
				</div>
				<Typography
					marginTop={'10px'}
					paddingRight={'40px'}
					fontSize={'0.8rem'}
					color={'#666666'}
				>
					If you turn off SMS and/or Email Notifications, you will
					still receive Joyner notifications via email and/or SMS for
					security and account management purposes, including
					receiving Joyner sign-in codes via SMS.
				</Typography>
			</div>
			<AppBar
				position='fixed'
				sx={{
					top: 'auto',
					bottom: 0,
					background: '#ffffff',
					paddingBottom: '60px',
				}}
			>
				<div
					style={{
						paddingTop: '20px',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						gap: '10px',
					}}
				>
					<Button
						variant='contained'
						fullWidth
						style={{ maxWidth: '250px' }}
						onClick={onEdit}
					>
						Edit Profile
					</Button>
					<Button
						variant='contained'
						fullWidth
						style={{ maxWidth: '250px' }}
						onClick={onAvatarEdit}
					>
						Change Avatar Photo
					</Button>
				</div>
			</AppBar>
		</>
	)
}

export default ViewProfile
