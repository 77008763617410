import React, { useEffect, useState } from 'react'
import { Typography, Button } from '@mui/material'
import { useUserContext } from 'contexts/UserContext'
import ActionComplete from 'components/ActionComplete'
import { StripeEmbeddedCheckoutDialog } from 'components/dialogs/StripeEmbeddedCheckoutDialog'

type Props = {
	setNextEnabled: (val: boolean) => void
}
const StartFreeTrial: React.FC<Props> = ({ setNextEnabled }) => {
	const { profile } = useUserContext()
	const [showCheckout, setShowCheckout] = useState(false)
	const [hasActiveSubscription, setHasActiveSubscription] = React.useState(
		() => {
			if (profile?.subscription_status === 'active') {
				return true
			}
			return false
		}
	)

	const handleStartFreeTrial = () => {
		setShowCheckout(true)
	}

	useEffect(() => {
		if (profile?.subscription_status === 'active') {
			setHasActiveSubscription(true)
		} else {
			setHasActiveSubscription(false)
		}
	}, [profile?.subscription_status])

	useEffect(() => {
		setNextEnabled(hasActiveSubscription)
	}, [hasActiveSubscription])

	return (
		<>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignContent: 'center',
					alignItems: 'center',
					padding: '20px 40px',
				}}
			>
				<Typography
					fontWeight={'bold'}
					paddingBottom={2}
					fontSize={'1.2rem'}
				>
					Start FREE Trial
				</Typography>
				<div
					style={{
						display: hasActiveSubscription ? 'none' : 'block',
					}}
				>
					<Typography paddingBottom={2}>
						Experience Joyner with full access for 90 days
						completely <strong>FREE</strong>&nbsp;&ndash;&nbsp;no
						credit card required, no commitment.
					</Typography>

					<Typography paddingBottom={1}>
						&#9989;&nbsp;Enjoy all Joyner features during{' '}
						<strong>FREE</strong> trial, nothing is held back
					</Typography>
					<Typography paddingBottom={1}>
						&#9989;&nbsp;Invite friends to your group—they join
						completely <strong>FREE</strong>
					</Typography>
					<Typography paddingBottom={2}>
						&#9989;&nbsp;Cancel anytime, no auto-charge
					</Typography>

					<Typography paddingBottom={2}>
						After 90 days, continue for just $2/month with unlimited
						use. Your trial won&apos;t auto-convert, so you stay in
						control.
					</Typography>

					<Typography paddingBottom={3}>
						Start now and see the difference!
					</Typography>
				</div>
				{hasActiveSubscription ? (
					<>
						<ActionComplete message='Successfully started FREE trial!' />
						<Typography paddingTop={4}>
							Tap <b>Next</b> to continue setting up your Joyner
							account.
						</Typography>
					</>
				) : (
					<Button
						variant='contained'
						color='success'
						onClick={handleStartFreeTrial}
						disabled={hasActiveSubscription}
					>
						Start Free Trial
					</Button>
				)}
			</div>
			<StripeEmbeddedCheckoutDialog
				isOpen={showCheckout}
				onClose={() => setShowCheckout(false)}
				type='trial'
				email={profile?.email || ''}
				customerId=''
				profileId={profile?.id || ''}
			/>
		</>
	)
}

export default StartFreeTrial
