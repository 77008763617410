import React, { useEffect, useState } from 'react'
import { Typography, Button, Box } from '@mui/material'
import { useSnackbarContext } from 'contexts/SnackbarContext'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import sth_iphone_s1 from 'images/sth-iphone-s1.png'
import sth_iphone_s2 from 'images/sth-iphone-s2.png'
import sth_iphone_s3 from 'images/sth-iphone-s3.png'
import sth_android_s1 from 'images/sth-android-s1.png'
import sth_android_s2 from 'images/sth-android-s2.png'
import sth_android_s3 from 'images/sth-android-s3.png'

type MobileOSTypes = 'iOS' | 'Android' | 'Windows Phone' | 'unknown'
const getMobileOperatingSystem = (userAgent: string): MobileOSTypes => {
	// Windows Phone must come first because its UA also contains "Android"
	if (/windows phone/i.test(userAgent)) {
		return 'Windows Phone'
	}

	if (/android/i.test(userAgent)) {
		return 'Android'
	}

	// iOS detection from: http://stackoverflow.com/a/9039885/177710
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
		return 'iOS'
	}

	return 'unknown'
}

type Props = {
	setNextEnabled: (val: boolean) => void
}
const SaveToHomescreen: React.FC<Props> = ({ setNextEnabled }) => {
	const [platform] = useState(() => {
		const userAgent =
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			navigator.userAgent || navigator.vendor || (window as any).opera

		return getMobileOperatingSystem(userAgent)
	})

	const { showSnackbar } = useSnackbarContext()

	useEffect(() => {
		setNextEnabled(true)
	}, [])

	const handleCopyURL = () => {
		navigator.clipboard.writeText('https://bea.joyner.app')
		showSnackbar('App link copied to clipboard', 'success')
	}

	const handleGetHelp = () => {
		let url = 'https://help.joyner.app/guides/03-save-to-homescreen/'
		if (platform === 'iOS') url += '#iphone-instructions-w-safari'
		if (platform === 'Android') url += '#android-instructions-w-chrome'
		window.open(url, '_blank')
	}

	const content = () => {
		switch (platform) {
			case 'iOS': {
				return (
					<Box>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'space-between',
								marginBottom: '0.6rem',
							}}
						>
							<Typography
								fontWeight={'500'}
								paddingTop={'0.2rem'}
								fontSize={'1.2rem'}
							>
								Install Joyner on iPhone
							</Typography>
							<Button
								variant='outlined'
								size='small'
								onClick={handleGetHelp}
							>
								Get Help
							</Button>
						</div>
						<Typography textAlign={'left'} marginBottom={2}>
							1. Scroll the page up until the icon bar appears at
							the bottom of the page. Tap the Share button.
						</Typography>
						<Box>
							<img src={sth_iphone_s1} width='300' />
						</Box>
						<Typography
							marginTop={1}
							marginBottom={2}
							textAlign={'left'}
						>
							2. Tap &apos;Add to Home Screen&apos; in the menu.
						</Typography>
						<Box>
							<img src={sth_iphone_s2} width='300' />
						</Box>
						<Typography
							marginTop={1}
							marginBottom={2}
							textAlign={'left'}
						>
							3. Tap &apos;Add&apos; in the dialog.
						</Typography>
						<Box>
							<img src={sth_iphone_s3} width='300' />
						</Box>
						<Typography marginTop={2} textAlign={'left'}>
							4. You&apos;re Done! Close this browser tab and open
							Joyner from the app icon on your phone&apos;s Home
							Screen.
						</Typography>
					</Box>
				)
			}
			case 'Android': {
				return (
					<Box>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'space-between',
								marginBottom: '0.6rem',
							}}
						>
							<Typography
								fontWeight={'500'}
								paddingTop={'0.2rem'}
							>
								Install Joyner on Android
							</Typography>
							<Button
								variant='outlined'
								size='small'
								onClick={handleGetHelp}
							>
								Get Help
							</Button>
						</div>
						<Typography marginBottom={2} textAlign={'left'}>
							1. Tap the Menu button in header bar at the top
							right of the screen.
						</Typography>
						<Box>
							<img src={sth_android_s1} width='300' />
						</Box>
						<Typography
							marginTop={1}
							marginBottom={2}
							textAlign={'left'}
						>
							2. Tap &apos;Install app&apos; in the menu.
						</Typography>
						<Box>
							<img src={sth_android_s2} width='300' />
						</Box>
						<Typography
							marginTop={1}
							marginBottom={2}
							textAlign={'left'}
						>
							3. Tap &apos;Install&apos; in the dialog.
						</Typography>
						<Box>
							<img src={sth_android_s3} width='300' />
						</Box>
						<Typography marginTop={2} textAlign={'left'}>
							4. You&apos;re Done! Close this browser tab and open
							Joyner from the app icon on your phone&apos;s Home
							Screen.
						</Typography>
					</Box>
				)
			}
			default: {
				return (
					<Box>
						<Typography
							marginTop={1}
							fontWeight={'bold'}
							textAlign={'left'}
						>
							Joyner is optimized as an app for use on your phone.
						</Typography>
						<Typography marginTop={3} textAlign={'left'}>
							To open Joyner on your phone:
						</Typography>
						<Typography
							marginTop={1}
							marginLeft={3}
							textAlign={'left'}
						>
							1. Tap the button below to copy the app URL and
							paste it into your phone&apos;s browser window.
						</Typography>
						<Typography
							marginTop={1}
							textAlign={'left'}
							marginBottom={4}
							marginLeft={3}
						>
							2. Once there, Sign In and then follow the
							instructions on the screen to install the app and
							start using Joyner!
						</Typography>
						<Button
							startIcon={<ContentCopyIcon />}
							variant='contained'
							onClick={handleCopyURL}
						>
							Copy App URL
						</Button>
					</Box>
				)
			}
		}
	}

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				textAlign: 'center',
				padding: '0 3rem',
			}}
		>
			{/* INTRO PART */}
			<Typography fontWeight={'bold'} paddingTop={2} fontSize={'1.1rem'}>
				Congrats on Becoming a Joyner!
			</Typography>
			<Typography paddingTop={2} paddingBottom={2}>
				Get started by installing Joyner on your phone — just follow
				these simple steps.
			</Typography>
			{/* <Typography paddingTop={2} paddingBottom={3}>
				Once installed, open Joyner from the app icon on your
				phone&apos;s Home Screen.
			</Typography> */}

			{content()}
		</Box>
	)
}

export default SaveToHomescreen
